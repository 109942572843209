var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "close-page-wrapper" }, [
    _c("div", { staticClass: "page-title" }, [
      _c("div", { staticClass: "go-to-start" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-transparent",
            on: {
              click: function($event) {
                return _vm.reloadPage()
              }
            }
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "30",
                  height: "30",
                  viewBox: "0 0 30 40",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M26.1666 28.8333C26.8333 29.5 26.8333 30.5 26.1666 31.1666C25.8333 31.5 25.4999 31.6666 24.9999 31.6666C24.4999 31.6666 24.1666 31.5 23.8333 31.1666L13.8333 21.1666C13.1666 20.5 13.1666 19.5 13.8333 18.8333L23.8333 8.83331C24.4999 8.16665 25.4999 8.16665 26.1666 8.83331C26.8333 9.49998 26.8333 10.5 26.1666 11.1666L17.3333 20L26.1666 28.8333Z",
                    fill: "#AE2418"
                  }
                })
              ]
            ),
            _vm._v(" " + _vm._s(_vm.getTrans("reload", _vm.selectedLang)) + " ")
          ]
        )
      ]),
      _c("div", { staticClass: "page-info-wrapper" }, [
        _c(
          "div",
          {
            staticClass:
              "page-info-inner page-info-inner--full page-info-inner--transparent"
          },
          [
            _c("div", { staticClass: "page-info-row mt20" }, [
              _c("h2", [
                _vm._v(_vm._s(_vm.getTrans("succes_login", _vm.selectedLang)))
              ])
            ]),
            _c("div", { staticClass: "page-info-row mt40" }, [
              _c(
                "svg",
                {
                  attrs: {
                    width: "195",
                    height: "195",
                    viewBox: "0 0 195 195",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d:
                        "M187.127 32.2486C187.694 32.8147 188.144 33.4872 188.452 34.2275C188.759 34.9678 188.917 35.7615 188.917 36.563C188.917 37.3646 188.759 38.1582 188.452 38.8986C188.144 39.6389 187.694 40.3113 187.127 40.8774L101.814 126.19C101.248 126.757 100.576 127.208 99.8354 127.515C99.0951 127.822 98.3014 127.98 97.4999 127.98C96.6983 127.98 95.9047 127.822 95.1643 127.515C94.424 127.208 93.7516 126.757 93.1855 126.19L56.623 89.6274C56.0564 89.0608 55.607 88.3882 55.3004 87.6479C54.9938 86.9077 54.8359 86.1143 54.8359 85.313C54.8359 84.5118 54.9938 83.7183 55.3004 82.9781C55.607 82.2378 56.0564 81.5652 56.623 80.9986C57.1896 80.4321 57.8622 79.9826 58.6025 79.676C59.3427 79.3694 60.1361 79.2116 60.9374 79.2116C61.7386 79.2116 62.5321 79.3694 63.2723 79.676C64.0126 79.9826 64.6852 80.4321 65.2518 80.9986L97.4999 113.259L178.498 32.2486C179.064 31.6812 179.737 31.2309 180.477 30.9237C181.217 30.6165 182.011 30.4584 182.812 30.4584C183.614 30.4584 184.408 30.6165 185.148 30.9237C185.888 31.2309 186.561 31.6812 187.127 32.2486Z",
                      fill: "#50B440"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d:
                        "M97.5001 30.4687C84.2426 30.4687 71.2828 34.4 60.2595 41.7655C49.2363 49.131 40.6447 59.5998 35.5713 71.8482C30.4979 84.0965 29.1704 97.5743 31.7568 110.577C34.3433 123.58 40.7274 135.524 50.1019 144.898C59.4763 154.273 71.4202 160.657 84.423 163.243C97.4258 165.83 110.904 164.502 123.152 159.429C135.4 154.355 145.869 145.764 153.235 134.74C160.6 123.717 164.531 110.757 164.531 97.4999C164.531 95.8838 165.173 94.3338 166.316 93.191C167.459 92.0482 169.009 91.4062 170.625 91.4062C172.241 91.4062 173.791 92.0482 174.934 93.191C176.077 94.3338 176.719 95.8838 176.719 97.4999C176.717 114.938 170.96 131.889 160.343 145.722C149.726 159.556 134.841 169.499 117.996 174.011C101.152 178.523 83.2889 177.351 67.1785 170.676C51.0682 164.001 37.6103 152.197 28.8921 137.095C20.1739 121.992 16.6826 104.435 18.9596 87.1458C21.2366 69.8568 29.1546 53.8022 41.4858 41.4718C53.8169 29.1415 69.8721 21.2244 87.1612 18.9485C104.45 16.6727 122.007 20.1651 137.109 28.8843C137.838 29.2652 138.481 29.7896 139.002 30.4257C139.522 31.0619 139.908 31.7967 140.137 32.586C140.366 33.3753 140.433 34.2028 140.334 35.0187C140.235 35.8345 139.972 36.6219 139.561 37.3334C139.149 38.045 138.598 38.666 137.941 39.159C137.283 39.6521 136.533 40.0071 135.735 40.2026C134.936 40.3982 134.107 40.4302 133.296 40.2968C132.485 40.1634 131.709 39.8674 131.016 39.4265C120.83 33.5361 109.267 30.4456 97.5001 30.4687Z",
                      fill: "#50B440"
                    }
                  })
                ]
              )
            ]),
            _c("div", { staticClass: "page-info-row mt10" }, [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.getTrans("go_to_balance", _vm.selectedLang)
                  )
                }
              })
            ]),
            _c("div", { staticClass: "page-info-row" }, [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm
                      .getTrans("after_go_to_depo", _vm.selectedLang)
                      .replace("depo_number", this.productName)
                  )
                }
              })
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }