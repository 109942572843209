

































































































import Vue from "vue";
import {Component} from "vue-property-decorator";
import './statetment-data.less'
import {translateService} from "@/service/translate";
import 'simple-keyboard/build/css/index.css';
import {connectService} from "@/service/connect";
import {stepperService} from "@/service/stepper";
import {dataService} from "@/service/dataService";
import loader from "@/components/loader/loader.vue";
import pdf from 'vue-pdf'
declare let VUE_APP_BASE_URL


@Component({
  components: {
    loader,
    pdf
  }
})
export default class StatetmentData extends Vue {
  public selectedLang = "";
  public selectedTab = 0;
  public statement_data: any = [];

  mounted() {
    translateService.data.subscribe(
        (ev) => {
          if (ev.event == 'update') {
            this.selectedLang = ev.selectedLang;
          }
        }
    );

    this.getStatetment()
  }

  handleScroll(event, index) {
    console.log(event, index)
  }

  getStatetment() {
    let formData: Object = connectService.createFormData({"token": dataService.getToken(), "lang": this.selectedLang});

    connectService.submit(formData, connectService.get_statements_url + '&not_accepted_or_expired_by_driver_id=' + dataService.getTransporterData().driver_id, 'GET', dataService.getLoginToken()).then((response) => {
      if (response) {
        this.statement_data = [];
        response.data.data.forEach((statement) => {
          this.statement_data.push(
              {
                'id': statement.id,
                'title': statement.name,
                'pdf': connectService.getBaseUrl()+statement.pdf,
                'acceptance_label': statement.acceptance_label,
                'accept': false,
                'touched': false,
              }
          )
        })
        if(this.statement_data.length == 0) {
          this.goToNextPage()
        }

      }
    })


  }

  getTrans(key, selectedLang) {
    return translateService.getTranslation(key, selectedLang);
  }
  pdfNumber = 0
  getPDFNumber(number){
    if(typeof number != 'undefined'){
      this.pdfNumber = number+1
    }
  }


  isValidForm() {
    return this.statement_data.filter((statement) => {
      return statement.accept;
    }).length == this.statement_data.length;
  }

  sendData() {
    // @ts-ignore
    if (!this.isValidForm()) {

    } else {
      dataService.setStatementsData(this.statement_data)
      this.goToNextPage()
    }
  }

  goToNextPage() {
    stepperService.setStepValidate(3);
    stepperService.setSelectedStepId(4);
  }




}
