






































































import Vue from "vue";
import {Component, Watch, Emit} from "vue-property-decorator";
import './carriage-data.less'
import {translateService} from "@/service/translate";
import 'simple-keyboard/build/css/index.css';
import Keyboard from "simple-keyboard";
import {connectService} from "@/service/connect";
import {stepperService} from "@/service/stepper";
import {popupService} from "@/service/popupService";
import {dataService} from "@/service/dataService";
import {carriage} from "@/model/model";


@Component({
  components: {}
})
export default class carriageData extends Vue {
  public selectedLang = "";
  public freight: carriage = {
    id: '',
    partner: '',
    transporter: '',
    address: '',
    product: '',
    quantity: '',
    type: 0,
    shipping_type: ''
  };


  mounted() {
    translateService.data.subscribe(
        (ev) => {
          if (ev.event == 'update') {
            this.selectedLang = ev.selectedLang;
          }
        }
    );

    dataService.data.subscribe(
        (ev) => {
          if (ev.event == 'updateTransport') {
            this.freight = ev.data;
          }
        }
    );
    this.getCarriageData()
  }


  getCarriageData() {
    const formData: any = connectService.createFormData({"token": dataService.getToken()});
    connectService.submit(formData, connectService.get_carriage_data_url + dataService.getToken(), 'GET', dataService.getLoginToken()).then((response) => {
      if (response.data.success) {
        dataService.setCarriage(
            response.data.shipping, response.data.shipping_type
        );

      }
    })


  }

  getTrans(key, selectedLang) {
    return translateService.getTranslation(key, selectedLang);
  }

  goToNextPage() {

    stepperService.setStepValidate(1);
    stepperService.setSelectedStepId(2);
  }

  reloadPage() {
    location.reload();
  }


}
