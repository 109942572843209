import Vue from 'vue'
import Terminal from './Terminal.vue'
import './assets/less/main.less';
import VueTheMask from 'vue-the-mask'
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'
import Vuelidate from 'vuelidate'
// import VeeValidate from "vee-validate";



Vue.config.productionTip = false
Vue.use(VueTheMask);
Vue.use(Vuelidate);
// Vue.use(VeeValidate, {locale: "hu"});

Vue.component('v-select', vSelect)



new Vue({
  render: h => h(Terminal),
}).$mount('#app')
