import {async, ReplaySubject, Subject} from "rxjs";
import {language, pEvent, popupEvent, popupModel, stepperEvent, transEvent} from "@/model/model";
import {translateService} from "@/service/translate";

export class PopupService {
    private popUp ={
        title: "",
        description: "",
        action: "",
        isActive: false
    }



    constructor() {
        this._data = new ReplaySubject<popupEvent>();
        this.data.next({event: 'update', data: this.getPopupData()})
    }


    private _data: ReplaySubject<popupEvent> = new ReplaySubject<popupEvent>();


    public get data(): ReplaySubject<popupEvent> {
        return this._data;
    }

    public set data(c: ReplaySubject<popupEvent>) {
        this._data = c;
    }
    
    getPopupData() :popupModel {
        return this.popUp;
    }

    setPopUp(title, description, action, isActive){
        this.popUp.title = title;
        this.popUp.description = description;
        this.popUp.action = action;
        this.popUp.isActive = isActive;
    }

    setPopupActiveState(isActive){
        this.popUp.isActive = isActive;
        if(this.popUp.action == "refreshPage"){
            location.reload();
        }
    }
    


}

export const popupService = new PopupService();