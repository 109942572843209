var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "stepper" } }, [
    _c(
      "div",
      { staticClass: "stepper-wrapper" },
      _vm._l(_vm.stepper, function(step, index) {
        return step.isViewable
          ? _c(
              "div",
              {
                staticClass: "step-wrapper",
                class: {
                  active: step.isActive,
                  disabled: !step.isAvailable,
                  isChecked: step.isChecked
                },
                on: {
                  click: function($event) {
                    return _vm.selectStep(step.id)
                  }
                }
              },
              [
                _c("div", {
                  staticClass: "step-icon-wrapper",
                  domProps: { innerHTML: _vm._s(step.icon) }
                }),
                _c("div", { staticClass: "step-text-wrapper" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.getTrans(step.name, _vm.selectedLang)))
                  ])
                ]),
                index + 1 != _vm.stepper.length - 2
                  ? _c("div", { staticClass: "step-next-wrapper" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M13.0834 10.5834L8.08341 15.5834C7.91675 15.75 7.75008 15.8334 7.50008 15.8334C7.25008 15.8334 7.08341 15.75 6.91675 15.5834C6.58341 15.25 6.58341 14.75 6.91675 14.4167L11.3334 10L6.91675 5.58335C6.58341 5.25002 6.58341 4.75002 6.91675 4.41669C7.25008 4.08335 7.75008 4.08335 8.08341 4.41669L13.0834 9.41669C13.4167 9.75002 13.4167 10.25 13.0834 10.5834Z",
                              fill: "black"
                            }
                          }),
                          _c(
                            "mask",
                            {
                              attrs: {
                                id: "st01",
                                "mask-type": "alpha",
                                maskUnits: "userSpaceOnUse",
                                x: "6",
                                y: "4",
                                width: "8",
                                height: "12"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M13.0834 10.5834L8.08341 15.5834C7.91675 15.75 7.75008 15.8334 7.50008 15.8334C7.25008 15.8334 7.08341 15.75 6.91675 15.5834C6.58341 15.25 6.58341 14.75 6.91675 14.4167L11.3334 10L6.91675 5.58335C6.58341 5.25002 6.58341 4.75002 6.91675 4.41669C7.25008 4.08335 7.75008 4.08335 8.08341 4.41669L13.0834 9.41669C13.4167 9.75002 13.4167 10.25 13.0834 10.5834Z",
                                  fill: "white"
                                }
                              })
                            ]
                          ),
                          _c("g", { attrs: { mask: "url(#st01)" } }, [
                            _c("rect", {
                              attrs: {
                                width: "20",
                                height: "20",
                                fill: "#242424"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e()
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }