























































import Vue from "vue";
import {Component, Watch, Emit} from "vue-property-decorator";
import './entry-code.less'
import {translateService} from "@/service/translate";
import 'simple-keyboard/build/css/index.css';
import Keyboard from "simple-keyboard";
import {connectService} from "@/service/connect";
import {stepperService} from "@/service/stepper";
import {popupService} from "@/service/popupService";
import {dataService} from "@/service/dataService";


@Component({
  components: {}
})
export default class entryCode extends Vue {
  public selectedLang = "";

  public entry = {
    entry1: {value: "", isActive: true},
    entry2: {value: "", isActive: false},
    entry3: {value: "", isActive: false},
    entry4: {value: "", isActive: false}
  }
  public keyboard: any;
  public keyboardClass = "simple-keyboard";
  public selectedInput = "entry1";
  public lasKey = "";


  public localError: boolean = false;
  public localErrorTitle: string = "";

  mounted() {

    let formData: Object = connectService.createFormData({"email": "calmitline.terminal@pentacom.hu", "password": "uEThy6KiqlsE"});
    connectService.login(formData).then(response => {
      dataService.setLoginToken(response.data.access_token)
    })
    translateService.data.subscribe(
        (ev) => {
          if (ev.event == 'update') {
            this.selectedLang = ev.selectedLang;
            this.initKeyBoard();
          }
        }
    );

    this.changeInput();
    this.initKeyBoard();

    this.setFocus(this.selectedInput);

    if (dataService.getToken() > 0) {
      for (let i = 0; i < 4; i++) {
        this.entry['entry' + (i + 1)].value = dataService.getToken().toString().slice(i, i + 1)
      }
    }

  }

  initKeyBoard() {

    this.keyboard = new Keyboard({
      onKeyPress: this.onKeyPress,
      layout: {
        default: [
          "1 2 3",
          "4 5 6",
          "7 8 9",
          "0",
          "{bksp}"
        ],
      },
      display: {
        '{bksp}': this.getTrans('delete', this.selectedLang),
      },
      buttonTheme: [
        {
          class: "zeroTheme",
          buttons: "0"
        }
      ]
    });
  }


  onKeyPress(button) {
    if (typeof this.entry[this.selectedInput] != undefined) {

      if (button == "{bksp}" && this.entry[this.selectedInput].value.length == 0) {
        let index = this.getElementIndex(this.selectedInput);
        this.selectedInput = this.getElementName(this.selectedInput) + (index - (index != 1 ? 1 : 0));
        this.entry[this.selectedInput].value = button == "{bksp}" ? '' : button;
        this.lasKey = button;
        this.setActive(this.selectedInput);
        this.setFocus(this.selectedInput);
      } else {
        this.entry[this.selectedInput].value = button == "{bksp}" ? '' : button;
        this.changeInput();
        this.lasKey = button;
      }
    }
  }


  focusIn(element) {
    if (this.entry[element].value.length == 1) {

      this.setFocus(element);
      //@ts-ignore
      // this.$refs[element].select();
      this.selectedInput = element;
      this.setActive(element);
    } else {
      if (this.getFirstEmptyInput() == '') {

        this.setFocus(element);
        //@ts-ignore
        this.selectedInput = element;

        this.setActive(element);
      } else {
        this.setFocus(this.getFirstEmptyInput());
        this.selectedInput = this.getFirstEmptyInput();
        this.setActive(this.getFirstEmptyInput());
      }
    }

  }

  changeInput() {
    for (const [key, value] of Object.entries(this.entry)) {
      if (this.entry[key].isActive) {
        if (this.entry[key].value.length == 1) {

          let index = this.getElementIndex(key);
          this.selectedInput = this.getElementName(key) + (index + (index != 4 ? 1 : 0));

          this.setActive(this.selectedInput);
          this.setFocus(this.selectedInput);

          return true;
        }
      }
    }
  }

  setActive(element) {
    for (const [key, value] of Object.entries(this.entry)) {
      if (key == element) {
        value.isActive = true;

      } else {
        value.isActive = false;
      }
    }
  }

  getFirstEmptyInput() {
    let emptyImput = '';
    for (const [key, value] of Object.entries(this.entry)) {
      if (value.value.length == 0 && emptyImput == '') {
        emptyImput = key;
      }
    }
    return emptyImput;
  }

  setFocus(element) {
    //@ts-ignore
    this.$refs[element].focus();
  }

  getElementName(element) {
    return element.slice(0, 5);
  }

  getElementIndex(element) {
    return parseInt(element.slice(5));
  }

  getTrans(key, selectedLang) {
    return translateService.getTranslation(key, selectedLang);
  }

  getCode() {
    return this.entry.entry1.value + this.entry.entry2.value + this.entry.entry3.value + this.entry.entry4.value;
  }

  sendCode() {

    if (this.getCode().length == 4) {
      this.sendCodeAndRefreshToken()
    }
  }

  sendCodeAndRefreshToken(){
    let formData: Object = connectService.createFormData({"email": "calmitline.terminal@pentacom.hu", "password": "uEThy6KiqlsE"});
    connectService.login(formData).then(response => {
      dataService.setLoginToken(response.data.access_token)
      setTimeout(()=>{
        let formData: Object = connectService.createFormData({"token": this.getCode()});

        connectService.submit(formData, connectService.post_check_token_url + this.getCode(), 'GET', dataService.getLoginToken()).then((response) => {

          if (response.data.success) {
            dataService.resetCarriage();
            dataService.setToken(response.data.original_token);
            stepperService.setStepValidate(0);
            stepperService.setSelectedStepId(1);

          } else {

            this.localError = true;
            this.localErrorTitle = response.data.message;

          }

          if (false) {
            if (response.data.status == "success") {
              dataService.resetCarriage();
              dataService.setToken(response.data.original_token);
              stepperService.setStepValidate(0);
              stepperService.setSelectedStepId(1);

            } else if (response.data.status == "not_found") {

              this.localError = true;
              this.localErrorTitle = this.getTrans("error_not_valid_entry_code", this.selectedLang);

            } else if (response.data.status == "deleted") {

              popupService.setPopUp(
                  this.getTrans('pop_error_1_title', this.selectedLang),
                  this.getTrans('pop_error_1_desc', this.selectedLang),
                  'refreshPage',
                  false,
              );

            } else if (response.data.status == "completed") {

              popupService.setPopUp(
                  this.getTrans('pop_error_2_title', this.selectedLang),
                  this.getTrans('pop_error_2_desc', this.selectedLang),
                  'refreshPage',
                  false,
              );

            } else if (response.data.status == "completed") {

              popupService.setPopUp(
                  this.getTrans('pop_error_3_title', this.selectedLang),
                  this.getTrans('pop_error_3_desc', this.selectedLang),
                  'refreshPage',
                  false,
              );

            } else {

              this.localError = true;
              this.localErrorTitle = this.getTrans("error_entry_code_general", this.selectedLang);

            }
          }
        })
      }, 100)
    })
  }


}
