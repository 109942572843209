var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "freight-data-wrapper" }, [
    _c("div", { staticClass: "page-title" }, [
      _c("h2", [
        _vm._v(_vm._s(_vm.getTrans("freight_title", _vm.selectedLang)))
      ])
    ]),
    _c("div", { staticClass: "page-desc page-desc--freight" }, [
      _c("p", {
        domProps: {
          innerHTML: _vm._s(_vm.getTrans("freight_desc", _vm.selectedLang))
        }
      })
    ]),
    _c("div", { staticClass: "page-info-wrapper" }, [
      _c("div", { staticClass: "page-info-inner" }, [
        _c("div", { staticClass: "page-info-row" }, [
          _c("div", { staticClass: "freight-info-title" }, [
            _c("p", [_vm._v(_vm._s(_vm.getTrans("id", _vm.selectedLang)))])
          ]),
          _c("div", { staticClass: "freight-info-value" }, [
            _c("p", { staticClass: "bigger" }, [
              _vm._v(
                _vm._s(
                  (_vm.freight.shipping_type.indexOf("FuelShipping") > -1
                    ? "UZA-"
                    : "ARU-") + _vm.freight.id
                )
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "page-info-row" }, [
          _c("div", { staticClass: "freight-info-title" }, [
            _c("p", [_vm._v(_vm._s(_vm.getTrans("partner", _vm.selectedLang)))])
          ]),
          _c("div", { staticClass: "freight-info-value" }, [
            _c("p", { staticClass: "bigger" }, [
              _vm._v(_vm._s(_vm.freight.partner))
            ])
          ])
        ]),
        _vm.freight.shipping_type.indexOf("FuelShipping") == -1
          ? _c("div", { staticClass: "page-info-row" }, [
              _c("div", { staticClass: "freight-info-title" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.getTrans("freight_customer_name", _vm.selectedLang)
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "freight-info-value" }, [
                _c("p", [_vm._v(_vm._s(_vm.freight.transporter))])
              ])
            ])
          : _vm._e(),
        _vm.freight.shipping_type.indexOf("FuelShipping") == -1
          ? _c("div", { staticClass: "page-info-row" }, [
              _c("div", { staticClass: "freight-info-title" }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.getTrans("address", _vm.selectedLang)))
                ])
              ]),
              _c("div", { staticClass: "freight-info-value" }, [
                _c("p", [_vm._v(_vm._s(_vm.freight.address))])
              ])
            ])
          : _vm._e(),
        _vm.freight.shipping_type.indexOf("FuelShipping") == -1
          ? _c("div", { staticClass: "page-info-row" }, [
              _c("div", { staticClass: "freight-info-title" }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.getTrans("product", _vm.selectedLang)))
                ])
              ]),
              _c("div", { staticClass: "freight-info-value" }, [
                _c("p", [_vm._v(_vm._s(_vm.freight.product))])
              ])
            ])
          : _vm._e(),
        _vm.freight.shipping_type.indexOf("FuelShipping") == -1
          ? _c("div", { staticClass: "page-info-row" }, [
              _c("div", { staticClass: "freight-info-title" }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.getTrans("quantity", _vm.selectedLang)))
                ])
              ]),
              _c("div", { staticClass: "freight-info-value" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      typeof _vm.freight.formated_quantity != "undefined"
                        ? _vm.freight.formated_quantity
                        : _vm.freight.quantity
                    )
                  )
                ])
              ])
            ])
          : _vm._e()
      ])
    ]),
    _c("div", { staticClass: "page-nav-wrapper" }, [
      _c("div", { staticClass: "page-nav-inner" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-success",
            on: {
              click: function($event) {
                return _vm.goToNextPage()
              }
            }
          },
          [_vm._v(_vm._s(_vm.getTrans("yes", _vm.selectedLang)))]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-warning",
            on: {
              click: function($event) {
                return _vm.reloadPage()
              }
            }
          },
          [_vm._v(_vm._s(_vm.getTrans("no", _vm.selectedLang)))]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }