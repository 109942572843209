















































import Vue from "vue";
import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import './custom-select.less'
import 'simple-keyboard/build/css/index.css';


@Component({
  components: {}
})
export default class customSelect extends Vue {
  @Prop() options;
  @Prop() value;
  @Prop() valueId;
  @Prop() refName;
  @Prop() element;
  @Prop() setOutFocus;
  @Prop() errorText;
  @Prop() label;
  @Prop() notFoundText;

  public isOpenSelector: boolean = false;
  public selectedID: number = -1;
  public displayName: string = "";
  public isTouched: boolean = false;
  public inputValue = "";

  mounted() {
    this.inputValue = this.value
    this.selectedID = this.valueId
  }

  select(option) {
    this.selectedID = option.id;
    this.displayName = option[this.label];
    this.inputValue = "";
    this.$emit('input', option[this.label]);
    this.$emit('selectedId', option.id, this.element);
    this.isOpenSelector = !this.isOpenSelector;
  }

  focusIn() {
    this.$emit('input', "");
    this.isOpenSelector = true;
    this.isTouched = true;
  }

  updateData() {
    if (this.value != this.displayName ||
        this.displayName.length == 1 && this.inputValue.length == 0) {
      this.inputValue = this.value;
      this.selectedID = this.valueId
    }

    this.$emit('input', this.value);

    this.$emit('selectedId', this.selectedID, this.element);


  }
  updateInputData() {
    if (this.value != this.displayName ||
        this.displayName.length == 1 && this.inputValue.length == 0) {
      this.inputValue = this.value;
      this.selectedID = this.valueId
      this.displayName = this.value
    }
    this.$emit('input', this.value);

    this.$emit('selectedId', this.selectedID, this.element);


  }

  filter() {
    let filteredOptions = this.options.filter((o) => {
      return o[this.label].toUpperCase().indexOf(this.inputValue.toUpperCase()) > -1;
    })
    if (filteredOptions.length == 0) {
      this.$emit('notFound', false, this.element);
    } else {
      this.$emit('notFound', true, this.element);
    }
    return filteredOptions;
  }

  changeInput(input) {
    console.log(input)
  }

  toggleInput(){
    this.isOpenSelector =!this.isOpenSelector
    this.focusIn()
  }

  @Watch("setOutFocus", {immediate: true, deep: true})
  onSetOutFocus(isOpenSelector) {

    this.isOpenSelector = isOpenSelector;
  }

  @Watch("value", {immediate: true, deep: true})
  onValueChange(newValue, oldValue) {
    this.updateInputData();
  }
  @Watch("isOpenSelector", {immediate: true, deep: true})
  onOpen(newValue, oldValue) {
    this.$emit('openDropdown', this.element)
  }


}
