




















































import Vue from "vue";
import {Component, Watch, Emit} from "vue-property-decorator";
import './info-page.less'
import {translateService} from "@/service/translate";
import 'simple-keyboard/build/css/index.css';
import Keyboard from "simple-keyboard";
import {connectService} from "@/service/connect";
import {stepperService} from "@/service/stepper";
import {popupService} from "@/service/popupService";
import {dataService} from "@/service/dataService";
import {carriage} from "@/model/model";
import {Validations} from "vuelidate-property-decorators";
import {required, email, minValue, minLength, maxLength, numeric} from 'vuelidate/lib/validators';
import loader from "@/components/loader/loader.vue";


@Component({
  components: {loader}
})
export default class infoPage extends Vue {
  public selectedLang = "";
  public selectedTab = 0;
  public info_data: any = [];
  public statement_data = []

  mounted() {
    translateService.data.subscribe(
        (ev) => {
          if (ev.event == 'update') {
            this.selectedLang = ev.selectedLang;
          }
        }
    );

    dataService.statementData.subscribe(
        (ev) => {
          this.statement_data = ev.data
        }
    )

    this.getMinerInfo()

  }


  getMinerInfo() {
    let formData: Object = connectService.createFormData({
      "token": dataService.getToken(),
      "lang": this.selectedLang
    });

    connectService.submit(formData, connectService.get_miner_info_url, 'GET', dataService.getLoginToken()).then((response) => {
      if (response) {
        this.info_data = response.data.data;

        if (this.info_data.length == 0) {
          this.sendData()
        }

      }
    })


  }

  getTrans(key, selectedLang) {
    return translateService.getTranslation(key, selectedLang);
  }

  goToNextPage() {
    stepperService.setSelectedStepId(2);
  }

  reloadPage() {
    location.reload();
  }


  sendData() {
    this.submitForm()
  }

  private submitForm() {
    let formData: Object = connectService.createFormData({...dataService.getTransDataTypeCalmit(), ...dataService.getStatements(dataService.getStatementsData())});

    connectService.submit(formData,
        connectService.put_transporter_data_url,
        'POST',
        dataService.getLoginToken()).then((response) => {
          console.log(response)
          if (response.data && response.data.success) {
            stepperService.setSelectedStepId(5);
          } else {

            if (response.data!.status) {
              if (response.data.status == 'terminal.token_not_found') {
                dataService.setEntryErrorMessage(this.getTrans('token_not_found', this.selectedLang))
              } else if (response.data.status == 'terminal.t1entry_error') {
                dataService.setEntryErrorMessage(this.getTrans('t1entry_error', this.selectedLang))
              } else if (response.data.status == 'terminal.t1entry_delivery_address_error') {
                dataService.setEntryErrorMessage(this.getTrans('t1entry_delivery_address_error', this.selectedLang))
              }else{
                dataService.setEntryErrorMessage(this.getTrans('undefined_error', this.selectedLang))
              }

            }else{
              dataService.setEntryErrorMessage(this.getTrans('undefined_error', this.selectedLang))
            }
            stepperService.setSelectedStepId(6);
          }
        }
    ).catch((response) => {
      dataService.setEntryErrorMessage(this.getTrans('undefined_error', this.selectedLang))
      stepperService.setSelectedStepId(6);
    })
  }


}
