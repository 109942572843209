var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "custom-select-wrapper",
      class: {
        "focus-in": _vm.isOpenSelector,
        error: _vm.selectedID < 0 && !_vm.isOpenSelector && _vm.isTouched
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "selected-title",
          class: { min: _vm.isOpenSelector },
          on: { click: _vm.focusIn }
        },
        [_c("p", [_vm._v(_vm._s(_vm.displayName))])]
      ),
      _c(
        "div",
        {
          staticClass: "arrow-wrapper",
          class: { open: _vm.isOpenSelector },
          on: {
            click: function($event) {
              return _vm.toggleInput()
            }
          }
        },
        [
          _c(
            "svg",
            {
              staticClass: "vs__open-indicator",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "14",
                height: "10",
                role: "presentation"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"
                }
              })
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpenSelector,
              expression: "isOpenSelector"
            }
          ],
          staticClass: "custom-select-input-wrapper"
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue"
              }
            ],
            ref: _vm.refName,
            attrs: { type: "text" },
            domProps: { value: _vm.inputValue },
            on: {
              change: [
                function($event) {
                  return _vm.updateData()
                },
                _vm.changeInput
              ],
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.inputValue = $event.target.value
              }
            }
          })
        ]
      ),
      _vm.isOpenSelector
        ? _c(
            "div",
            { staticClass: "custom-select-elements" },
            [
              _vm._l(_vm.filter(), function(option, index) {
                return _c(
                  "div",
                  {
                    staticClass: "custom-select-element",
                    class: { selected: _vm.value == option.id },
                    on: {
                      click: function($event) {
                        return _vm.select(option)
                      }
                    }
                  },
                  [_c("p", [_vm._v(_vm._s(option[_vm.label]))])]
                )
              }),
              _vm.filter().length == 0
                ? _c("div", { staticClass: "custom-select-element error" }, [
                    _c("p", [_vm._v(_vm._s(_vm.notFoundText))])
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _c("p", { staticClass: "error" }, [_vm._v(_vm._s(_vm.errorText))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }