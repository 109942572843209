import {Observable, ReplaySubject, Subject} from 'rxjs';
import {step, stepperEvent} from "@/model/model";
import {translateService} from "@/service/translate";

export class StepperService {

    _steps: step[] = [
        {
            id: 0,
            name: 'stepper_login_code',
            icon: '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.28333 12.6209C6.20417 12.6209 6.125 12.5417 6.125 12.4626V9.45423C6.125 9.37507 6.20417 9.2959 6.28333 9.2959H11.0333L11.9042 10.3251L12.775 10.6417C12.8542 10.6417 12.8542 10.7209 12.8542 10.8001V12.4626C12.8542 12.5417 12.775 12.6209 12.6958 12.6209H6.28333Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 15.9458V2.80417C3.75 2.4875 3.9875 2.25 4.30417 2.25H14.3583C14.675 2.25 14.9125 2.4875 14.9125 2.80417V15.9458C14.9125 16.2625 14.675 16.5 14.3583 16.5H4.30417C3.9875 16.5 3.75 16.2625 3.75 15.9458Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M10.8751 4.30835H7.7876" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M8.02482 13.0168C8.02482 13.2543 7.86649 13.4127 7.62899 13.4127C7.39149 13.4127 7.23315 13.2543 7.23315 13.0168" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M11.6667 13.0168C11.6667 13.2543 11.5083 13.4127 11.2708 13.4127C11.0333 13.4127 10.875 13.2543 10.875 13.0168" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '</svg>',
            component: 'entryCode',
            isActive: true,
            isChecked: true,
            isValid: false,
            isViewable: true
        },
        {
            id: 1,
            name: 'stepper_freight_details',
            icon: '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '    <path d="M2.77295 6.95679H3.59998" stroke="#7C7C7E" stroke-width="1.2973" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M5.01074 6.95679H6.3729" stroke="#7C7C7E" stroke-width="1.2973" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M2.77295 8.95142H6.37295" stroke="#7C7C7E" stroke-width="1.2973" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M2.77295 10.946H4.47565" stroke="#7C7C7E" stroke-width="1.2973" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M14.1566 10.946H10.7998" stroke="#7C7C7E" stroke-width="1.2973" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M9.24298 10.946H8.65919C8.56189 10.946 8.4646 10.8487 8.4646 10.7514V7.15138C8.4646 7.05408 8.56189 6.95679 8.65919 6.95679H14.3997L15.4214 8.4649L16.5403 8.80544C16.6376 8.85408 16.6862 8.90273 16.6862 9.00003V10.7514C16.6862 10.8487 16.5889 10.946 16.4916 10.946H15.7619" stroke="#7C7C7E" stroke-width="1.2973" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M10.0215 11.6757C10.4514 11.6757 10.7999 11.3272 10.7999 10.8973C10.7999 10.4674 10.4514 10.1189 10.0215 10.1189C9.59165 10.1189 9.24316 10.4674 9.24316 10.8973C9.24316 11.3272 9.59165 11.6757 10.0215 11.6757Z" stroke="#7C7C7E" stroke-width="1.2973" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M14.9349 11.6757C15.3648 11.6757 15.7132 11.3272 15.7132 10.8973C15.7132 10.4674 15.3648 10.1189 14.9349 10.1189C14.505 10.1189 14.1565 10.4674 14.1565 10.8973C14.1565 11.3272 14.505 11.6757 14.9349 11.6757Z" stroke="#7C7C7E" stroke-width="1.2973" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M12.8432 12.3081V13.1838C12.8432 13.3784 12.6972 13.4757 12.5513 13.4757H1.26479C1.0702 13.4757 0.9729 13.3298 0.9729 13.1838V4.67031C0.9729 4.47572 1.11885 4.37842 1.26479 4.37842H12.5513C12.7459 4.37842 12.8432 4.52436 12.8432 4.67031V5.54599" stroke="#7C7C7E" stroke-width="1.2973" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '</svg>',
            component: 'carriageData',
            isActive: false,
            isChecked: false,
            isValid: false,
            isViewable: true
        },
        {
            id: 2,
            name: 'stepper_transport_data',
            icon: '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '    <path d="M13.4 13.6375H8.17505" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M8.4502 7.1687H13.7439L15.3252 9.61244L17.0439 10.1874C17.1814 10.2593 17.2502 10.3312 17.2502 10.4749V13.3499C17.2502 13.4937 17.1127 13.6374 16.9752 13.6374H15.8064" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M5.83758 13.6374H4.87509C4.73759 13.6374 4.6001 13.4937 4.6001 13.3499V11.1218" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M7.0064 14.8593C7.65188 14.8593 8.17514 14.3122 8.17514 13.6374C8.17514 12.9626 7.65188 12.4155 7.0064 12.4155C6.36092 12.4155 5.83765 12.9626 5.83765 13.6374C5.83765 14.3122 6.36092 14.8593 7.0064 14.8593Z" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M14.5689 14.8593C15.2144 14.8593 15.7376 14.3122 15.7376 13.6374C15.7376 12.9626 15.2144 12.4155 14.5689 12.4155C13.9234 12.4155 13.4001 12.9626 13.4001 13.6374C13.4001 14.3122 13.9234 14.8593 14.5689 14.8593Z" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M4.59999 11.05C6.72628 11.05 8.44997 9.24792 8.44997 7.02499C8.44997 4.80205 6.72628 3 4.59999 3C2.4737 3 0.75 4.80205 0.75 7.02499C0.75 9.24792 2.4737 11.05 4.59999 11.05Z" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M4.59985 7.09668V8.89355" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M4.59985 5.94677C4.97955 5.94677 5.28735 5.62498 5.28735 5.22802C5.28735 4.83107 4.97955 4.50928 4.59985 4.50928C4.22016 4.50928 3.91235 4.83107 3.91235 5.22802C3.91235 5.62498 4.22016 5.94677 4.59985 5.94677Z" fill="#7C7C7E"/>\n' +
                '</svg>\n',
            component: 'transportData',
            isActive: false,
            isChecked: false,
            isValid: false,
            isViewable: true
        },
        {
            id: 3,
            name: 'stepper_statements',
            icon: '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '    <path d="M5.78772 4.88306H11.8285" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M5.78772 7.90332H11.8285" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M12.9905 16.5H4.62636C3.69702 16.5 3 15.7255 3 14.8736V3.87636C3 2.94701 3.77446 2.25 4.62636 2.25H12.9905C13.9198 2.25 14.6168 3.02446 14.6168 3.87636V14.8736C14.6168 15.803 13.9198 16.5 12.9905 16.5Z" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M7.87891 12.7051L8.8857 13.7119C8.96315 13.7893 9.11803 13.7893 9.19548 13.7119L11.6737 11.2336" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '</svg>\n',
            component: 'statetmentData',
            isActive: false,
            isChecked: false,
            isValid: false,
            isViewable: true
        },
        {
            id: 4,
            name: 'info',
            icon: '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '    <path d="M5.78772 4.88306H11.8285" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M5.78772 7.90332H11.8285" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M12.9905 16.5H4.62636C3.69702 16.5 3 15.7255 3 14.8736V3.87636C3 2.94701 3.77446 2.25 4.62636 2.25H12.9905C13.9198 2.25 14.6168 3.02446 14.6168 3.87636V14.8736C14.6168 15.803 13.9198 16.5 12.9905 16.5Z" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M7.87891 12.7051L8.8857 13.7119C8.96315 13.7893 9.11803 13.7893 9.19548 13.7119L11.6737 11.2336" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '</svg>\n',
            component: 'infoPage',
            isActive: false,
            isChecked: false,
            isValid: false,
            isViewable: false
        },
        {
            id: 5,
            name: 'closePage',
            icon: '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '    <path d="M5.78772 4.88306H11.8285" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M5.78772 7.90332H11.8285" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M12.9905 16.5H4.62636C3.69702 16.5 3 15.7255 3 14.8736V3.87636C3 2.94701 3.77446 2.25 4.62636 2.25H12.9905C13.9198 2.25 14.6168 3.02446 14.6168 3.87636V14.8736C14.6168 15.803 13.9198 16.5 12.9905 16.5Z" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M7.87891 12.7051L8.8857 13.7119C8.96315 13.7893 9.11803 13.7893 9.19548 13.7119L11.6737 11.2336" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '</svg>\n',
            component: 'closePage',
            isActive: false,
            isChecked: false,
            isValid: true,
            isViewable: false
        },
        {
            id: 6,
            name: 'errorPage',
            icon: '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '    <path d="M5.78772 4.88306H11.8285" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M5.78772 7.90332H11.8285" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M12.9905 16.5H4.62636C3.69702 16.5 3 15.7255 3 14.8736V3.87636C3 2.94701 3.77446 2.25 4.62636 2.25H12.9905C13.9198 2.25 14.6168 3.02446 14.6168 3.87636V14.8736C14.6168 15.803 13.9198 16.5 12.9905 16.5Z" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '    <path d="M7.87891 12.7051L8.8857 13.7119C8.96315 13.7893 9.11803 13.7893 9.19548 13.7119L11.6737 11.2336" stroke="#7C7C7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>\n' +
                '</svg>\n',
            component: 'errorPage',
            isActive: false,
            isChecked: false,
            isValid: true,
            isViewable: false
        }
    ]
    _selectedLang = "";

    constructor() {
        this._data = new ReplaySubject<stepperEvent>();
        this.data.next({event: 'update', data: this.getSteps()})
        translateService.data.subscribe(
            (ev) => {
                if (ev.event == 'update') {
                    this._selectedLang = ev.selectedLang;

                    (this.data as Subject<stepperEvent>).next({event: 'update', data: this.getSteps()});
                }
            }
        );
    }

    private _data: ReplaySubject<stepperEvent> = new ReplaySubject<stepperEvent>();


    public get data(): ReplaySubject<stepperEvent> {
        return this._data;
    }

    public set data(c: ReplaySubject<stepperEvent>) {
        this._data = c;
    }

    getSteps(): step[] {
        return this._steps;
    }


    getTrans(key, lang): string {
        return translateService.getTranslation(key, lang);
    }

    setSelectedStepId(stepId) {
        if (this.isValidSelectedStep(stepId)) {
            let isEnableCheck = true;
            this._steps.map((s) => {
                if (s.id == stepId) {
                    s.isActive = true;
                    isEnableCheck = false;
                } else {
                    s.isActive = false;
                }
                if (isEnableCheck) {
                    s.isChecked = true;
                } else {
                    s.isChecked = false;
                }
            });

            (this.data as Subject<stepperEvent>).next({event: 'update', data: this.getSteps()});

        }
    }

    isValidSelectedStep(stepId) {
        switch (stepId) {
            case 0:
                return true;
                break;
            case 1:
                if (this._steps[0].isValid) {
                    return true;
                } else {
                    return false;
                }
                break;
            case 2:
                if (this._steps[0].isValid && this._steps[1].isValid) {
                    return true;
                } else {
                    return false;
                }
                break;
            case 3:
                if (this._steps[0].isValid && this._steps[1].isValid && this._steps[2].isValid) {
                    return true;
                } else {
                    return false;
                }
                break;
            case 4:
                if (this._steps[0].isValid && this._steps[1].isValid && this._steps[2].isValid && this._steps[3].isValid) {
                    return true;
                } else {
                    return false;
                }
                break;
            default:
                return true;

        }
    }

    setStepValidate(stepId){
        this._steps[stepId].isValid = true;
    }
}

export const stepperService = new StepperService();