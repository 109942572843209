

































import Vue from "vue";
import {Component, Watch, Emit} from "vue-property-decorator";
import './error-page.less'
import {translateService} from "@/service/translate";
import 'simple-keyboard/build/css/index.css';
import {connectService} from "@/service/connect";
import {stepperService} from "@/service/stepper";
import {dataService} from "@/service/dataService";


@Component({
    components: {}
})
export default class errorPage extends Vue {
    public selectedLang = "";
    public productName = '';
    public info_data: any = [];

    mounted() {
        translateService.data.subscribe(
            (ev) => {
                if (ev.event == 'update') {
                    this.selectedLang = ev.selectedLang;
                }
            }
        );

    }

    getErrorMessage(){
      return dataService.getEntryErrorMessage()
    }



    getTrans(key, selectedLang) {
        return translateService.getTranslation(key, selectedLang);
    }


    reloadPage() {
        location.reload();
    }



}
