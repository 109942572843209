var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "statetment-data-wrapper" },
    [
      _vm.statement_data.length > 0
        ? [
            _c("div", { staticClass: "page-title" }, [
              _c("h2", [
                _vm._v(
                  _vm._s(_vm.getTrans("statement_title", _vm.selectedLang))
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "statements-wrapper" },
              [
                _c(
                  "div",
                  { staticClass: "statement-tab-wrapper" },
                  _vm._l(_vm.statement_data, function(statement, index) {
                    return _c(
                      "div",
                      {
                        staticClass: "statement-tab-element",
                        class: {
                          active: index == _vm.selectedTab,
                          disabled:
                            index > 0 && !_vm.statement_data[index - 1].accept
                        },
                        on: {
                          click: function($event) {
                            index === 0
                              ? (_vm.selectedTab = index)
                              : _vm.statement_data[index - 1].accept
                              ? (_vm.selectedTab = index)
                              : index - 1
                          }
                        }
                      },
                      [_c("p", [_vm._v(_vm._s(statement.title))])]
                    )
                  }),
                  0
                ),
                _vm._l(_vm.statement_data, function(statement, index) {
                  return index == _vm.selectedTab
                    ? _c("div", { staticClass: "statement-wrapper" }, [
                        _c("div", { staticClass: "page-info-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "page-info-inner page-info-inner--full"
                            },
                            [
                              _c("div", { staticClass: "page-info-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "statement-tab-content",
                                    attrs: {
                                      id: "statement-tab-content-" + index
                                    },
                                    on: { scroll: _vm.handleScroll }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pdf-wrapper" },
                                      [
                                        _c("pdf", {
                                          attrs: {
                                            src: statement.pdf,
                                            page: 1
                                          },
                                          on: { "num-pages": _vm.getPDFNumber }
                                        }),
                                        _vm._l(_vm.pdfNumber, function(i) {
                                          return _vm.pdfNumber > 0 &&
                                            i < _vm.pdfNumber - 1
                                            ? _c("pdf", {
                                                key: i,
                                                attrs: {
                                                  src: statement.pdf,
                                                  page: i
                                                }
                                              })
                                            : _vm._e()
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "page-nav-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "page-nav-inner page-info-inner--full"
                            },
                            [
                              _c("div", { staticClass: "checkbox-wrapper" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check-label",
                                    class: {
                                      error:
                                        !statement.accept && statement.touched
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: statement.accept,
                                          expression: "statement.accept"
                                        }
                                      ],
                                      ref: "transportAccept",
                                      refInFor: true,
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "checkbox",
                                        value: "0",
                                        name: "accept"
                                      },
                                      domProps: {
                                        checked: Array.isArray(statement.accept)
                                          ? _vm._i(statement.accept, "0") > -1
                                          : statement.accept
                                      },
                                      on: {
                                        click: function($event) {
                                          statement.touched = true
                                        },
                                        change: function($event) {
                                          var $$a = statement.accept,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = "0",
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  statement,
                                                  "accept",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  statement,
                                                  "accept",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(statement, "accept", $$c)
                                          }
                                        }
                                      }
                                    }),
                                    _c("span", {
                                      staticClass: "mdi mdi-name check-span"
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "checkbox-text" },
                                      [
                                        _vm._v(
                                          _vm._s(statement.acceptance_label)
                                        )
                                      ]
                                    ),
                                    _c("p", { staticClass: "error" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getTrans(
                                            "error_accept_statement",
                                            _vm.selectedLang
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "button-wrapper" }, [
                                _vm.statement_data.length - 1 !== index
                                  ? _c("div", { staticClass: "next" }, [
                                      !statement.accept
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-primary",
                                              class: {
                                                disabled:
                                                  statement.accept != true
                                              },
                                              on: {
                                                click: function($event) {
                                                  statement.touched = true
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getTrans(
                                                    "next",
                                                    _vm.selectedLang
                                                  )
                                                ) + " "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-primary",
                                              class: {
                                                disabled:
                                                  statement.accept != true
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.selectedTab = index + 1
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getTrans(
                                                    "next",
                                                    _vm.selectedLang
                                                  )
                                                ) + " "
                                              )
                                            ]
                                          )
                                    ])
                                  : _c("div", [
                                      !statement.accept
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-primary",
                                              class: {
                                                disabled: !statement.accept
                                              },
                                              on: {
                                                click: function($event) {
                                                  statement.touched = true
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getTrans(
                                                    "next_button",
                                                    _vm.selectedLang
                                                  )
                                                ) + " "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-primary",
                                              class: {
                                                disabled: !statement.accept
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.sendData()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getTrans(
                                                    "next_button",
                                                    _vm.selectedLang
                                                  )
                                                ) + " "
                                              )
                                            ]
                                          )
                                    ])
                              ])
                            ]
                          )
                        ])
                      ])
                    : _vm._e()
                })
              ],
              2
            )
          ]
        : [_c("loader")]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }