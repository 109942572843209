




























import Vue from "vue";
import {Component} from "vue-property-decorator";
import stepper from "./components/stepper/stepper.vue";
//@ts-ignore
import {translateService} from './service/translate.ts';
import languageSelectorMini from "@/components/language-selector-mini/language-selector-mini.vue";
import {step} from "@/model/model";
import {stepperService} from "@/service/stepper";
import entryCode from "@/components/entry-code/entry-code.vue";
import popup from "@/components/popup/popup.vue";
import carriageData from "@/components/carriage-data/carriage-data.vue";
import transportData from "@/components/transport-data/transport-data.vue";
import statetmentData from "@/components/statement-data/statetment-data.vue";
import infoPage from "@/components/info-page/info-page.vue";
import closePage from "@/components/close-page/close-page.vue";
import errorPage from "@/components/error-page/error-page.vue";

@Component({
  components: {
    stepper,
    languageSelectorMini,
    entryCode,
    popup,
    carriageData,
    statetmentData,
    infoPage,
    closePage,
    transportData,
    errorPage
  }
})

export default class Terminal extends Vue {
  public stepper: step[] = [];
  public selectedLang = "";
  public activeComponent = "entryCode";

  mounted() {
    const logData = {
      call:'Terminal.vue',
      VUE_APP_BASE_URL:process.env.VUE_APP_BASE_URL,
      VUE_APP_IS_USE_RUN_TIME_VARIABLE:process.env.VUE_APP_IS_USE_RUN_TIME_VARIABLE
    };
    console.table(logData);


    stepperService.data.subscribe(
        (ev) => {
          if (ev.event == 'update') {
            this.stepper = ev.data;
            this.setSelectedComponent();
          }
        }
    );
    translateService.data.subscribe(
        (ev) => {
          if (ev.event == 'update') {
            this.selectedLang = ev.selectedLang;
          }
        }
    );

  }

  setSelectedComponent() {
    this.stepper.map((s) => {
      if (s.isActive) {
        this.activeComponent = s.component;
      }
    })
  }

  isEnableStepper() {
    let enabledStepper = false;
    this.stepper.map((s) => {
      if (s.isActive && s.isViewable) {
        enabledStepper = true;
      }
    })

    return enabledStepper;
  }

  getTrans(key, selectedLang) {
    return translateService.getTranslation(key, selectedLang);
  }
}
