
























































































































































































































import Vue from "vue";
import {Component, Emit, Watch} from "vue-property-decorator";
import './transport-data.less'
import {translateService} from "@/service/translate";
import 'simple-keyboard/build/css/index.css';
import {stepperService} from "@/service/stepper";
import {dataService} from "@/service/dataService";
import {transporter_data} from "@/model/model";
import {required, email, minValue, minLength, maxLength, numeric} from 'vuelidate/lib/validators';
import {Validations} from 'vuelidate-property-decorators';
import Keyboard from "simple-keyboard";
import customSelect from "@/components/custom-select/custom-select.vue";
import {connectService} from "@/service/connect";
import {popupService} from "@/service/popupService";


@Component({
  components: {
    customSelect
  }
})
export default class transportData extends Vue {
  public selectedLang = "";
  public selectedInput = "shipping_note_number";
  public keyboardClass = "simple-keyboard";
  public keyboard: any;
  public is: any;
  public isMounted: boolean = false;
  public transporter_data: transporter_data = {
    transport_id: '',
    shipping_note_number: '',
    driver_name: '',
    truck_number_plate: '',
    trailer_number_plate: '',
    driver_id: 0,
    truck_id: 0,
    trailer_id: 0,
    maximum_weight: '',
    transportIsCustomer: false,
    transportAccept: false,
    driver_data: [],
    truck_data: [],
    trailer_data: [],
    trailer_filtered_data: [],
    organizationable_type: '',
    organizationable_id: '',
    contruck_fulfillment: 0,
    truck_type: -1,
    trailer_type: -1,
  };

  public keyboardKeys = {
    hu: {
      default: [
        "0 1 2 3 4 5 6 7 8 9 ö ü ó {bksp}",
        "{tab} q w e r t z u i o p ő ú ű",
        "{lock} a s d f g h j k l é á",
        "{shift} í y x c v b n m , . -",
        "@ {space}",
      ],
      shift: [
        "' 1 2 3 4 5 6 7 8 9 Ö Ü Ó {bksp}",
        "{tab} Q W E R T Z U I O P Ő Ú Ű",
        "{lock} A S D F G H J K L É Á",
        "{shift} Í Y X C V B N M ? : _",
        "@ {space}",
      ],
    },
    en: {
      default: [
        "0 1 2 3 4 5 6 7 8 9 ( ) {bksp}",
        "{tab} q w e r t z u i o p ő ú ű",
        "{lock} a s d f g h j k l é á",
        "{shift} í y x c v b n m , . -",
        "@ {space}",
      ],
      shift: [
        "0 1 2 3 4 5 6 7 8 9 & % {bksp}",
        "{tab} Q W E R T Z U I O P Ő Ú",
        "{lock} A S D F G H J K L É Á",
        "{shift} Í Y X C V B N M ? : _",
        "@ {space}",
      ],
    },
    de: {
      default: [
        "0 1 2 3 4 5 6 7 8 9 ß {bksp}",
        "{tab} q w e r t z u i o p ő ú ű",
        "{lock} a s d f g h j k l é á",
        "{shift} í y x c v b n m , . -",
        "@ {space}",
      ],
      shift: [
        "0 1 2 3 4 5 6 7 8 9 Ö Ü Ó {bksp}",
        "{tab} Q W E R T Z U I O P Ő Ú",
        "{lock} A S D F G H J K L É Á",
        "{shift} Í Y X C V B N M ? : _",
        "@ {space}",
      ],
    }
  }

  async mounted() {
    this.transporter_data = dataService.getTransporterData()
    this.getTruckData()
    this.getTrailerData()
    this.getDriverData()
    this.setTrailerFilteredData()
    this.setMaximumWeight()

    translateService.data.subscribe(
        (ev) => {
          if (ev.event == 'update') {
            this.selectedLang = ev.selectedLang;
            this.initKeyBoard();

          }
        }
    );

    dataService.data.subscribe(
        (ev) => {
          if (ev.event == 'updateTransport') {
            this.transporter_data = ev.transporter_data;
            this.setTrailerFilteredData()
            this.setMaximumWeight()
          }
        }
    );
    this.focusIn("shipping_note_number");
    this.isMounted = true;
  }

  setMaximumWeight(){
    this.transporter_data.maximum_weight = this.getWeight(this.transporter_data.trailer_data, this.transporter_data.trailer_id) + this.getWeight(this.transporter_data.truck_data, this.transporter_data.truck_id)

  }

  getTruckData() {
    let url = ''
    if (this.transporter_data.organizationable_type.indexOf("FuelTransporter") >= 0) {
      url = connectService.get_truck_fuel_transporter_data_url + this.transporter_data.organizationable_id + '&status=1'
    } else if (this.transporter_data.organizationable_type.indexOf("Transporter") >= 0) {
      url = connectService.get_truck_transporter_data_url + this.transporter_data.organizationable_id + '&status=1'
    } else if (this.transporter_data.organizationable_type.indexOf("Partner") >= 0) {
      url = connectService.get_truck_partner_data_url + this.transporter_data.organizationable_id + '&status=1'
    }
    let formData: Object = connectService.createFormData({"token": dataService.getToken()});

    connectService.submit(formData, url, 'GET', dataService.getLoginToken()).then((response) => {
      dataService.setTransportDataTruck(response.data.data)
    })
  }

  getTrailerData() {
    let url = ''
    if (this.transporter_data.organizationable_type.indexOf("FuelTransporter") >= 0) {
      url = connectService.get_trailer_fuel_transporter_data_url + this.transporter_data.organizationable_id + '&status=1'
    } else if (this.transporter_data.organizationable_type.indexOf("Transporter") >= 0) {
      url = connectService.get_trailer_transporter_data_url + this.transporter_data.organizationable_id + '&status=1'
    } else if (this.transporter_data.organizationable_type.indexOf("Partner") >= 0) {
      url = connectService.get_trailer_partner_data_url + this.transporter_data.organizationable_id + '&status=1'
    }
    let formData: Object = connectService.createFormData({"token": dataService.getToken()});

    connectService.submit(formData, url, 'GET', dataService.getLoginToken()).then((response) => {
      dataService.setTransportDataTrailer(response.data.data)
    })
  }

  getDriverData() {
    let organizationableType = ''
    if (this.transporter_data.organizationable_type.indexOf("FuelTransporter") >= 0) {
      organizationableType = 'fuel_transporter'
    } else if (this.transporter_data.organizationable_type.indexOf("Transporter") >= 0) {
      organizationableType = 'transporter'
    } else if (this.transporter_data.organizationable_type.indexOf("Partner") >= 0) {
      organizationableType = 'partner'
    }
    const subcontruck = this.transporter_data.contruck_fulfillment ? 1 : 0

    let url = connectService.get_driver_data_url + '?organizationable_id=' + this.transporter_data.organizationable_id + '&organizationable_type=' + organizationableType + '&subcontruck=' + subcontruck + '&status=1'
    let formData: Object = connectService.createFormData({"token": dataService.getToken()});

    connectService.submit(formData, url, 'GET', dataService.getLoginToken()).then((response) => {
      dataService.setTransportDataDriver(response.data.data)
    })
  }

  getCarriageType() {
    return dataService.getCarriage().type;
  }

  getTrans(key, selectedLang) {
    return translateService.getTranslation(key, selectedLang);
  }

  focusIn(element) {
    this.selectedInput = element;
  }

  setFocus() {
    setTimeout(() => {
      //@ts-ignore
      if (this.$refs[this.selectedInput] && typeof this.$refs[this.selectedInput].focus === "function")
          //@ts-ignore
        this.$refs[this.selectedInput].focus()
    }, 100)

  }

  goToNextPage() {
    stepperService.setSelectedStepId(2);
  }

  focusToMacTotal(isInInput) {
    if (!isInInput) {
      //@ts-ignore
      this.$refs.maximum_weight.focus()

    }

    if (parseInt(this.transporter_data.maximum_weight) === 0) {
      this.transporter_data.maximum_weight = '';
    }
  }

  sendData() {
    // @ts-ignore
    if (this.$v.transporter_data.$invalid) {
      // @ts-ignore
      this.$v.transporter_data.$touch();
    } else {

      stepperService.setStepValidate(2);
      stepperService.setSelectedStepId(3);
      dataService.setTransportDataModel(this.transporter_data);


    }
  }

  isValidForm() {
    // @ts-ignore
    return !this.$v.transporter_data.$invalid;
  }


  initKeyBoard() {
    this.keyboard = new Keyboard({
      onKeyPress: this.onKeyPress,

      layout: {
        default: this.getKeyboard('default'),
        shift: this.getKeyboard('shift')
      },
      mergeDisplay: true,

      display: {
        '{bksp}': this.getTrans('delete', this.selectedLang),
        '{enter}': 'Enter',
        '{shift}': 'Shift',
        '{lock}': 'Caps Lock',
        '{tab}': 'Tab',
        '{space}': '<p></p>',
      },
      buttonTheme: [
        // {
        //     // class: "zeroTheme",
        //     // buttons: "0"
        // }
      ]
    });
  }

  getKeyboard(type) {
    let localLang = "hu"
    if (this.selectedLang == '') {
      localLang = "hu";
    }
    if (this.selectedLang == "hu" ||
        this.selectedLang == "en" ||
        this.selectedLang == "de") {
      localLang = this.selectedLang;
    } else {
      localLang = "en";
    }

    return this.keyboardKeys[localLang][type];
  }

  handleShift() {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  }

  onKeyPress(button) {
    this.setFocus()
    if (button === "{shift}" || button === "{lock}") {
      this.handleShift();
    }

    if (typeof this.transporter_data[this.selectedInput] != undefined) {

      if (button == "{bksp}") {
        if (this.transporter_data[this.selectedInput].length == 0) {

        } else {
          this.transporter_data[this.selectedInput] = this.transporter_data[this.selectedInput].slice(0, this.transporter_data[this.selectedInput].length - 1);
        }

      } else if (button === "{space}") {
        if (this.transporter_data[this.selectedInput].slice(this.transporter_data[this.selectedInput].length - 1) != " ") {

          this.transporter_data[this.selectedInput] = this.transporter_data[this.selectedInput] + " ";
        }

      } else if (button === "{shift}") {


      } else if (button === "{lock}") {


      } else if (button === "{tab}") {


      } else if (button === "{enter}") {


      } else {
        this.transporter_data[this.selectedInput] = this.transporter_data[this.selectedInput] + button;

      }
    }
  }

  setTruckID(id, element) {
    if(id > 0){
      this.transporter_data[element] = id;
      this.transporter_data.truck_type = this.getTruckType()
      this.setTrailerFilteredData()
      if((this.transporter_data.truck_type == 1 && this.transporter_data.trailer_type == 3) ||
          (this.transporter_data.truck_type == 2 && this.transporter_data.trailer_type == 4) ){
         this.setMaximumWeight()
      }else{
        if(this.transporter_data.truck_type > 0){
          this.transporter_data.trailer_id = -1
          this.transporter_data.trailer_type = -1
          this.transporter_data.trailer_number_plate = ''
        }
        this.transporter_data.maximum_weight = this.getWeight(this.transporter_data.truck_data, this.transporter_data.truck_id)

      }
    }

  }

  setTrailerID(id, element) {
    if(id > 0){
      this.transporter_data[element] = id;
      this.transporter_data.trailer_type = this.getTrailerType();
      this.setMaximumWeight()
    }
  }


  getWeight(vehicleArray, vehicleId){
    const vehicle = vehicleArray.filter((vehicle)=>{
      return vehicle.id == vehicleId
    })
    if(vehicle.length > 0){
      return vehicle[0].maximum_weight
    }else{
      return 0
    }
  }



  setTrailerFilteredData(){
    if(this.transporter_data.truck_type<1){
      this.transporter_data.trailer_filtered_data = this.transporter_data.trailer_data
    }else{
      this.transporter_data.trailer_filtered_data = this.transporter_data.trailer_data.filter((trailer :any)=>{
        if(this.transporter_data.truck_type<1){
          return true
        }else if(this.transporter_data.truck_type == 1 && trailer.type && trailer.type.id == 3){
          return true
        }else if(this.transporter_data.truck_type == 2 && trailer.type && trailer.type.id == 4){
          return true
        }
      })
    }

  }
  setID(id, element) {
    this.transporter_data[element] = id;
  }

  getTruckType(){
    const selectedTruck = this.transporter_data.truck_data.filter((truck)=>{
      return truck.id && truck.id == this.transporter_data.truck_id
    })
    if(selectedTruck.length == 1){
      return selectedTruck[0].type.id
    }else {
      return -1
    }

  }
  getTrailerType(){
    const selectedTrailer :any= this.transporter_data.trailer_data.filter((trailer :any)=>{
      return trailer.id && trailer.id == this.transporter_data.trailer_id
    })
    if(selectedTrailer.length == 1){
      return selectedTrailer[0].type.id
    }else{
      return -1
    }
  }

  notFound(id, element) {
    console.log('not found:', id, element);

  }

  openDropdown(elementName) {
    if (!this.isMounted) {
      return;
    }
    if (elementName == 'driver_id') {
      this.getDriverData()
    } else if (elementName == 'truck_id') {
      this.getTruckData()
    } else if (elementName == 'trailer_id') {
      this.getTrailerData()
    }
  }


  @Watch("transporter_data.maximum_weight", {immediate: true, deep: true})
  reformat(value) {
    if (typeof value != undefined) {
      if (value.toString().slice(0, 1) == "0" && value.length > 1) {
        this.transporter_data.maximum_weight = value.slice(1);
      }
    }

  }

  customRequired() {
    let isRequired = true
    //ts-ignore
    if (typeof this.transporter_data != "undefined" && typeof this.transporter_data.truck_id != "undefined" && this.transporter_data.truck_id != '') {
      this.transporter_data.truck_data.filter(truck => {
        if (typeof truck.id != "undefined" && truck.id == this.transporter_data.truck_id) {
          //ts-ignore
          if (typeof truck.type != "undefined" && typeof truck.type.id != "undefined" && truck.type.id == 1) {
            isRequired = false
          }
        }
      })
    }
    return isRequired
  }

  @Validations()
  validations = {
    transporter_data: {
      shipping_note_number: {required, minLength: minLength(3)},
      driver_name: {required, minLength: minLength(3)},
      truck_number_plate: {required, minLength: minLength(3)},
      trailer_number_plate: {customRequired: this.customRequired()},
      maximum_weight: {required, minLength: minLength(3)},
      transportAccept: {required, maxLength: maxLength(4)}
    }
  }


}
