var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "transport-data-wrapper" }, [
    _c("div", { staticClass: "page-title" }, [
      _c("h2", [
        _vm._v(_vm._s(_vm.getTrans("transport_title", _vm.selectedLang)))
      ])
    ]),
    _c("div", { staticClass: "page-info-wrapper" }, [
      _c("div", { staticClass: "page-info-inner page-info-inner--full" }, [
        _c("div", { staticClass: "page-info-row" }, [
          _c("div", { staticClass: "col-30" }, [
            _c("div", { staticClass: "transport-data-label" }, [
              _c("p", [
                _vm._v(_vm._s(_vm.getTrans("transport_id", _vm.selectedLang)))
              ])
            ]),
            _c("div", { staticClass: "transport-data-input-wrapper" }, [
              _c("p", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.transporter_data.transport_id))
              ])
            ])
          ]),
          _c("div", { staticClass: "col-30" }, [
            _c("div", { staticClass: "transport-data-label" }, [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.getTrans(
                      "transport_bill_of_lading_number",
                      _vm.selectedLang
                    )
                  )
                )
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "transport-data-input-wrapper",
                class: {
                  error: _vm.$v.transporter_data.shipping_note_number.$error
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.transporter_data.shipping_note_number,
                      expression: "transporter_data.shipping_note_number"
                    }
                  ],
                  ref: "shipping_note_number",
                  class: {
                    focusIn: _vm.selectedInput == "shipping_note_number"
                  },
                  staticStyle: { width: "210px" },
                  attrs: { type: "text" },
                  domProps: {
                    value: _vm.transporter_data.shipping_note_number
                  },
                  on: {
                    focusout: function($event) {
                      return _vm.$v.transporter_data.shipping_note_number.$touch()
                    },
                    focusin: function($event) {
                      return _vm.focusIn("shipping_note_number")
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.transporter_data,
                        "shipping_note_number",
                        $event.target.value
                      )
                    }
                  }
                }),
                _c("p", { staticClass: "error" }, [
                  _vm._v(
                    _vm._s(
                      _vm.getTrans(
                        "transport_error_bill_of_lading_number",
                        _vm.selectedLang
                      )
                    )
                  )
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "col-40" }, [
            _c("div", { staticClass: "transport-data-label" }, [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.getTrans("transport_driver_name", _vm.selectedLang)
                  )
                )
              ])
            ]),
            _vm.getCarriageType() == 1
              ? _c(
                  "div",
                  {
                    staticClass: "transport-data-input-wrapper",
                    class: {
                      error: _vm.$v.transporter_data.driver_name.$error
                    },
                    on: {
                      click: function($event) {
                        return _vm.focusIn("driver_name")
                      }
                    }
                  },
                  [
                    _c("custom-select", {
                      attrs: {
                        options: _vm.transporter_data.driver_data,
                        label: "name",
                        refName: "driver_name",
                        id: "driver_name",
                        inputId: "driver_name",
                        element: "driver_id",
                        valueId: _vm.transporter_data.driver_id,
                        setOutFocus:
                          _vm.selectedInput == "driver_name" ||
                          _vm.selectedInput == "driver_id",
                        errorText: _vm.getTrans(
                          "transport_error_transport_error_driver_name_id",
                          _vm.selectedLang
                        ),
                        notFoundText: _vm.getTrans(
                          "transport_error_no_driver_name",
                          _vm.selectedLang
                        )
                      },
                      on: {
                        selectedId: _vm.setID,
                        notFound: _vm.notFound,
                        click: function($event) {
                          _vm.selectedInput = "driver_name"
                        },
                        openDropdown: _vm.openDropdown
                      },
                      model: {
                        value: _vm.transporter_data.driver_name,
                        callback: function($$v) {
                          _vm.$set(_vm.transporter_data, "driver_name", $$v)
                        },
                        expression: "transporter_data.driver_name"
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "transport-data-input-wrapper",
                    class: { error: _vm.$v.transporter_data.driver_name.$error }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.transporter_data.driver_name,
                          expression: "transporter_data.driver_name"
                        }
                      ],
                      ref: "driver_name",
                      class: {
                        focusIn: _vm.selectedInput == "shipping_note_number"
                      },
                      attrs: { type: "text" },
                      domProps: { value: _vm.transporter_data.driver_name },
                      on: {
                        focusout: function($event) {
                          return _vm.$v.transporter_data.driver_name.$touch()
                        },
                        focusin: function($event) {
                          return _vm.focusIn("driver_name")
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.transporter_data,
                            "driver_name",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("p", { staticClass: "error" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getTrans(
                            "transport_driver_name",
                            _vm.selectedLang
                          )
                        )
                      )
                    ])
                  ]
                )
          ])
        ]),
        _c("div", { staticClass: "page-info-row" }, [
          _c("div", { staticClass: "col-30" }, [
            _c("div", { staticClass: "transport-data-label" }, [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.getTrans(
                      "transport_truck_number_plate",
                      _vm.selectedLang
                    )
                  )
                )
              ])
            ]),
            _vm.getCarriageType() == 1
              ? _c(
                  "div",
                  {
                    staticClass: "transport-data-input-wrapper",
                    class: {
                      error: _vm.$v.transporter_data.truck_number_plate.$error
                    },
                    on: {
                      click: function($event) {
                        return _vm.focusIn("truck_number_plate")
                      }
                    }
                  },
                  [
                    _c("custom-select", {
                      attrs: {
                        options: _vm.transporter_data.truck_data,
                        label: "number_plate",
                        refName: "truck_number_plate",
                        id: "truck_number_plate",
                        inputId: "truck_number_plate",
                        element: "truck_id",
                        valueId: _vm.transporter_data.truck_id,
                        setOutFocus:
                          _vm.selectedInput == "truck_number_plate" ||
                          _vm.selectedInput == "truck_id",
                        errorText: _vm.getTrans(
                          "transport_error_truck_id",
                          _vm.selectedLang
                        ),
                        notFoundText: _vm.getTrans(
                          "transport_error_no_truck_number_plate",
                          _vm.selectedLang
                        )
                      },
                      on: {
                        selectedId: _vm.setTruckID,
                        notFound: _vm.notFound,
                        click: function($event) {
                          _vm.selectedInput = "truck_number_plate"
                        },
                        openDropdown: _vm.openDropdown
                      },
                      model: {
                        value: _vm.transporter_data.truck_number_plate,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.transporter_data,
                            "truck_number_plate",
                            $$v
                          )
                        },
                        expression: "transporter_data.truck_number_plate"
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "transport-data-input-wrapper",
                    class: {
                      error: _vm.$v.transporter_data.truck_number_plate.$error
                    }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.transporter_data.truck_number_plate,
                          expression: "transporter_data.truck_number_plate"
                        }
                      ],
                      ref: "truck_number_plate",
                      class: {
                        focusIn: _vm.selectedInput == "truck_number_plate"
                      },
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.transporter_data.truck_number_plate
                      },
                      on: {
                        focusout: function($event) {
                          return _vm.$v.transporter_data.truck_number_plate.$touch()
                        },
                        focusin: function($event) {
                          return _vm.focusIn("truck_number_plate")
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.transporter_data,
                            "truck_number_plate",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("p", { staticClass: "error" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getTrans(
                            "transport_error_truck_number_plate",
                            _vm.selectedLang
                          )
                        )
                      )
                    ])
                  ]
                )
          ]),
          _c("div", { staticClass: "col-30" }, [
            _c("div", { staticClass: "transport-data-label" }, [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.getTrans(
                      "transport_trailer_number_plate",
                      _vm.selectedLang
                    )
                  )
                )
              ])
            ]),
            _vm.getCarriageType() == 1
              ? _c(
                  "div",
                  {
                    staticClass: "transport-data-input-wrapper",
                    class: {
                      error: _vm.$v.transporter_data.trailer_number_plate.$error
                    },
                    on: {
                      click: function($event) {
                        return _vm.focusIn("trailer_number_plate")
                      }
                    }
                  },
                  [
                    _c("custom-select", {
                      attrs: {
                        options: _vm.transporter_data.trailer_filtered_data,
                        label: "number_plate",
                        refName: "trailer_number_plate",
                        id: "trailer_number_plate",
                        inputId: "trailer_number_plate",
                        valueId: _vm.transporter_data.trailer_id,
                        element: "trailer_id",
                        setOutFocus:
                          _vm.selectedInput == "trailer_number_plate" ||
                          _vm.selectedInput == "trailer_id",
                        errorText: _vm.getTrans(
                          "transport_error_trailer_id",
                          _vm.selectedLang
                        ),
                        notFoundText: _vm.getTrans(
                          "transport_error_no_trailer_number_plate",
                          _vm.selectedLang
                        )
                      },
                      on: {
                        selectedId: _vm.setTrailerID,
                        notFound: _vm.notFound,
                        click: function($event) {
                          _vm.selectedInput = "trailer_number_plate"
                        },
                        openDropdown: _vm.openDropdown
                      },
                      model: {
                        value: _vm.transporter_data.trailer_number_plate,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.transporter_data,
                            "trailer_number_plate",
                            $$v
                          )
                        },
                        expression: "transporter_data.trailer_number_plate"
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "transport-data-input-wrapper",
                    class: {
                      error: _vm.$v.transporter_data.trailer_number_plate.$error
                    }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.transporter_data.trailer_number_plate,
                          expression: "transporter_data.trailer_number_plate"
                        }
                      ],
                      ref: "trailer_number_plate",
                      class: {
                        focusIn: _vm.selectedInput == "trailer_number_plate"
                      },
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.transporter_data.trailer_number_plate
                      },
                      on: {
                        focusout: function($event) {
                          return _vm.$v.transporter_data.trailer_number_plate.$touch()
                        },
                        focusin: function($event) {
                          return _vm.focusIn("trailer_number_plate")
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.transporter_data,
                            "trailer_number_plate",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("p", { staticClass: "error" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getTrans(
                            "transport_error_towing_license_plaete_number",
                            _vm.selectedLang
                          )
                        )
                      )
                    ])
                  ]
                )
          ]),
          _c("div", { staticClass: "col-40" }, [
            _c("div", { staticClass: "transport-data-label" }, [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.getTrans(
                      "transport_truck_weight_max_allowed",
                      _vm.selectedLang
                    )
                  )
                )
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "transport-data-input-wrapper",
                class: {
                  "ez-itt-frissul": true,
                  error: _vm.$v.transporter_data.maximum_weight.$error
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.transporter_data.maximum_weight,
                      expression: "transporter_data.maximum_weight"
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["#", "##", "###", "# ###", "## ###"],
                      expression: "['#', '##', '###', '# ###', '## ###']"
                    }
                  ],
                  ref: "maximum_weight",
                  class: { focusIn: _vm.selectedInput == "maximum_weight" },
                  attrs: { type: "text" },
                  domProps: { value: _vm.transporter_data.maximum_weight },
                  on: {
                    focusout: function($event) {
                      return _vm.$v.transporter_data.maximum_weight.$touch()
                    },
                    click: function($event) {
                      return _vm.focusToMacTotal(true)
                    },
                    focusin: function($event) {
                      return _vm.focusIn("maximum_weight")
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.transporter_data,
                        "maximum_weight",
                        $event.target.value
                      )
                    }
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "unit",
                    style: {
                      left:
                        50 +
                        _vm.transporter_data.maximum_weight.length * 10 +
                        "px"
                    },
                    on: {
                      click: function($event) {
                        return _vm.focusToMacTotal(false)
                      }
                    }
                  },
                  [_c("p", [_vm._v("kg")])]
                ),
                _c("p", { staticClass: "error" }, [
                  _vm._v(
                    _vm._s(
                      _vm.getTrans(
                        "transport_error_truck_weight_max_allowed",
                        _vm.selectedLang
                      )
                    )
                  )
                ])
              ]
            )
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "page-nav-wrapper" }, [
      _c("div", { staticClass: "page-nav-inner page-info-inner--full" }, [
        _c("div", { staticClass: "checkbox-wrapper" }, [
          _c(
            "label",
            {
              staticClass: "form-check-label",
              class: { error: _vm.$v.transporter_data.transportAccept.$error }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.transporter_data.transportAccept,
                    expression: "transporter_data.transportAccept"
                  }
                ],
                ref: "transportAccept",
                staticClass: "form-check-input",
                class: { focusIn: _vm.selectedInput == "transportAccept" },
                attrs: { type: "checkbox", value: "0", name: "accept" },
                domProps: {
                  checked: Array.isArray(_vm.transporter_data.transportAccept)
                    ? _vm._i(_vm.transporter_data.transportAccept, "0") > -1
                    : _vm.transporter_data.transportAccept
                },
                on: {
                  focusout: function($event) {
                    return _vm.$v.transporter_data.transportAccept.$touch()
                  },
                  focusin: function($event) {
                    return _vm.focusIn("transportAccept")
                  },
                  change: function($event) {
                    var $$a = _vm.transporter_data.transportAccept,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "0",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.transporter_data,
                            "transportAccept",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.transporter_data,
                            "transportAccept",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.transporter_data, "transportAccept", $$c)
                    }
                  }
                }
              }),
              _c("span", { staticClass: "mdi mdi-name check-span" }),
              _c("span", { staticClass: "checkbox-text" }, [
                _vm._v(
                  _vm._s(_vm.getTrans("transport_valid_data", _vm.selectedLang))
                )
              ]),
              _c("p", { staticClass: "error" }, [
                _vm._v(
                  _vm._s(
                    _vm.getTrans("transport_error_accept", _vm.selectedLang)
                  )
                )
              ])
            ]
          )
        ]),
        _c("div", { staticClass: "button-wrapper" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              class: { disabled: !_vm.isValidForm() },
              on: {
                click: function($event) {
                  return _vm.sendData()
                }
              }
            },
            [
              _vm._v(
                _vm._s(_vm.getTrans("next_button", _vm.selectedLang)) + " "
              )
            ]
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "keyboard-wrapper" }, [
      _c("div", { staticClass: "num-keyboard" }, [
        _c("div", { class: _vm.keyboardClass })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }