var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "close-page-wrapper" }, [
    _c("div", { staticClass: "page-title" }, [
      _c("div", { staticClass: "go-to-start" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-transparent",
            on: {
              click: function($event) {
                return _vm.reloadPage()
              }
            }
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "30",
                  height: "30",
                  viewBox: "0 0 30 40",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M26.1666 28.8333C26.8333 29.5 26.8333 30.5 26.1666 31.1666C25.8333 31.5 25.4999 31.6666 24.9999 31.6666C24.4999 31.6666 24.1666 31.5 23.8333 31.1666L13.8333 21.1666C13.1666 20.5 13.1666 19.5 13.8333 18.8333L23.8333 8.83331C24.4999 8.16665 25.4999 8.16665 26.1666 8.83331C26.8333 9.49998 26.8333 10.5 26.1666 11.1666L17.3333 20L26.1666 28.8333Z",
                    fill: "#AE2418"
                  }
                })
              ]
            ),
            _vm._v(" " + _vm._s(_vm.getTrans("reload", _vm.selectedLang)) + " ")
          ]
        )
      ]),
      _c("div", { staticClass: "page-info-wrapper" }, [
        _c(
          "div",
          {
            staticClass:
              "page-info-inner page-info-inner--full page-info-inner--transparent"
          },
          [
            _c("div", { staticClass: "page-info-row mt20" }, [
              _c("h2", { staticClass: "error" }, [
                _vm._v(_vm._s(_vm.getTrans("error_login", _vm.selectedLang)))
              ])
            ]),
            _c("div", { staticClass: "page-info-row mt40" }, [
              _c("p", [
                _vm._v(_vm._s(_vm.getTrans("system_info", _vm.selectedLang)))
              ])
            ]),
            _c("div", { staticClass: "page-info-row mt40" }, [
              _c("p", [_vm._v(_vm._s(_vm.getErrorMessage()))])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }