

























import Vue from "vue";
import {stepperService, StepperService} from "@/service/stepper";
import {step} from "@/model/model";
import {Component, Watch, Emit} from "vue-property-decorator";
import './popup.less'
import {translateService} from "@/service/translate";
import {popupService} from "@/service/popupService";


@Component({
    components: {}
})
export default class popup extends Vue {
    popupData = {
        title: "",
        description: "",
        action: "",
        isActive: true
    }
    public selectedLang = "hu";
    mounted() {
        popupService.data.subscribe(
            (ev) => {
                if (ev.event == 'update') {
                    this.popupData = ev.data;
                }
            }
        );

        translateService.data.subscribe(
            (ev) => {
                if (ev.event == 'update') {
                    this.selectedLang = ev.selectedLang;
                }
            }
        );

    }

    closePopup(){
        popupService.setPopupActiveState(false);
    }


    getTrans(key, selectedLang) {
        return translateService.getTranslation(key, selectedLang);
    }


}
