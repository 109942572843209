var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.popupData.isActive
    ? _c("div", { staticClass: "popup-wrapper" }, [
        _c("div", { staticClass: "popup-modal" }, [
          _c("div", { staticClass: "popup-header" }, [
            _c("div", { staticClass: "header-title" }, [
              _c("h3", { domProps: { innerHTML: _vm._s(_vm.popupData.title) } })
            ]),
            _c(
              "div",
              {
                staticClass: "header-close",
                on: {
                  click: function($event) {
                    return _vm.closePopup()
                  }
                }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d:
                          "M17.781 6.21888C17.8508 6.28854 17.9063 6.37131 17.9441 6.46243C17.9819 6.55354 18.0013 6.65122 18.0013 6.74988C18.0013 6.84853 17.9819 6.94621 17.9441 7.03733C17.9063 7.12844 17.8508 7.21121 17.781 7.28088L7.281 17.7809C7.14017 17.9217 6.94916 18.0008 6.75 18.0008C6.55084 18.0008 6.35983 17.9217 6.219 17.7809C6.07817 17.64 5.99905 17.449 5.99905 17.2499C5.99905 17.0507 6.07817 16.8597 6.219 16.7189L16.719 6.21888C16.7887 6.14903 16.8714 6.09362 16.9626 6.05581C17.0537 6.018 17.1514 5.99854 17.25 5.99854C17.3487 5.99854 17.4463 6.018 17.5375 6.05581C17.6286 6.09362 17.7113 6.14903 17.781 6.21888Z",
                        fill: "#242424"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d:
                          "M6.219 6.21888C6.14915 6.28854 6.09374 6.37131 6.05593 6.46243C6.01812 6.55354 5.99866 6.65122 5.99866 6.74988C5.99866 6.84853 6.01812 6.94621 6.05593 7.03733C6.09374 7.12844 6.14915 7.21121 6.219 7.28088L16.719 17.7809C16.8598 17.9217 17.0508 18.0008 17.25 18.0008C17.4492 18.0008 17.6402 17.9217 17.781 17.7809C17.9218 17.64 18.0009 17.449 18.0009 17.2499C18.0009 17.0507 17.9218 16.8597 17.781 16.7189L7.281 6.21888C7.21133 6.14903 7.12857 6.09362 7.03745 6.05581C6.94633 6.018 6.84865 5.99854 6.75 5.99854C6.65135 5.99854 6.55367 6.018 6.46255 6.05581C6.37143 6.09362 6.28867 6.14903 6.219 6.21888Z",
                        fill: "#242424"
                      }
                    })
                  ]
                )
              ]
            )
          ]),
          _c("div", { staticClass: "popup-body" }, [
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.popupData.description) }
            })
          ]),
          _c("div", { staticClass: "popup-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.closePopup()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.getTrans("ok", _vm.selectedLang)))]
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }