







































import Vue from "vue";
import {stepperService, StepperService} from "@/service/stepper";
import {step} from "@/model/model";
import {Component, Watch, Emit} from "vue-property-decorator";
import './stepper.less'
import {translateService} from "@/service/translate";


@Component({
    components: {}
})
export default class stepper extends Vue {
    public stepper: step[] = [];
    public selectedLang = "";

    mounted() {
        stepperService.data.subscribe(
            (ev) => {
                if (ev.event == 'update') {
                    this.stepper = ev.data;
                }
            }
        );
        translateService.data.subscribe(
            (ev) => {
                if (ev.event == 'update') {
                    this.selectedLang = ev.selectedLang;
                }
            }
        );

    }

    getTrans(key, selectedLang){
        return translateService.getTranslation(key, selectedLang);
    }

    selectStep(stepId) {
        stepperService.setSelectedStepId(stepId);
    }

}
