var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-page-wrapper" },
    [
      _vm.info_data.length > 0
        ? [
            _c("div", { staticClass: "page-title" }, [
              _c("h2", [
                _vm._v(
                  _vm._s(_vm.getTrans("actual_message_title", _vm.selectedLang))
                )
              ]),
              _c("div", { staticClass: "go-to-start" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-transparent",
                    on: {
                      click: function($event) {
                        return _vm.reloadPage()
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "30",
                          height: "30",
                          viewBox: "0 0 30 40",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M26.1666 28.8333C26.8333 29.5 26.8333 30.5 26.1666 31.1666C25.8333 31.5 25.4999 31.6666 24.9999 31.6666C24.4999 31.6666 24.1666 31.5 23.8333 31.1666L13.8333 21.1666C13.1666 20.5 13.1666 19.5 13.8333 18.8333L23.8333 8.83331C24.4999 8.16665 25.4999 8.16665 26.1666 8.83331C26.8333 9.49998 26.8333 10.5 26.1666 11.1666L17.3333 20L26.1666 28.8333Z",
                            fill: "#AE2418"
                          }
                        })
                      ]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(_vm.getTrans("reload", _vm.selectedLang)) +
                        " "
                    )
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "statements-wrapper" }, [
              _c("div", { staticClass: "statement-wrapper" }, [
                _c("div", { staticClass: "page-info-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "page-info-inner page-info-inner--full" },
                    [
                      _c("div", { staticClass: "page-info-row" }, [
                        _c(
                          "div",
                          { staticClass: "statement-tab-content" },
                          _vm._l(_vm.info_data, function(info) {
                            return _c("div", { staticClass: "info-element" }, [
                              _c("h2", [_vm._v(_vm._s(info.name))]),
                              _c("div", {
                                domProps: { innerHTML: _vm._s(info.content) }
                              })
                            ])
                          }),
                          0
                        )
                      ])
                    ]
                  )
                ]),
                _c("div", { staticClass: "page-nav-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "page-nav-inner page-info-inner--full" },
                    [
                      _c("div", { staticClass: "checkbox-wrapper" }),
                      _c("div", { staticClass: "button-wrapper" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            on: {
                              click: function($event) {
                                return _vm.sendData()
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.getTrans("i_read", _vm.selectedLang)) +
                                " "
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ])
              ])
            ])
          ]
        : [_c("loader")]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }