//ts-ignore
// @ts-ignore
import axios from "axios";

declare let VUE_APP_BASE_URL

export class ConnectService {

    public base_url = process.env.VUE_APP_IS_USE_RUN_TIME_VARIABLE ? typeof VUE_APP_BASE_URL != "undefined" ? VUE_APP_BASE_URL : process.env.VUE_APP_BASE_URL  :  process.env.VUE_APP_BASE_URL;
    public post_check_token_url = this.base_url + '/api/v1/shipping-tokens/check/';
    public get_carriage_data_url = this.base_url +  '/api/v1/terminal/shipping/';

    public get_truck_transporter_data_url = this.base_url + '/api/v1/vehicles?truckTransporters=';
    public get_truck_fuel_transporter_data_url = this.base_url + '/api/v1/vehicles?truckFuelTransporters=';
    public get_truck_partner_data_url = this.base_url + '/api/v1/vehicles?truckPartners=';

    public get_trailer_transporter_data_url = this.base_url + '/api/v1/vehicles?trailerTransporters=';
    public get_trailer_fuel_transporter_data_url = this.base_url + '/api/v1/vehicles?trailerFuelTransporters=';
    public get_trailer_partner_data_url = this.base_url + '/api/v1/vehicles?trailerPartners=';

    public get_driver_data_url = this.base_url + '/api/v1/drivers';


    public get_statements_url = this.base_url + '/api/v1/legalstatements?status=1';
    public get_miner_info_url = this.base_url + '/api/v1/mineinformations?status=1';

    public put_transporter_data_url = this.base_url + '/api/v1/terminal/t1/entry';


    public login_url = this.base_url + '/api/v1/sanctum/token';
    constructor() {
    }

    getBaseUrl(){
        return this.base_url
    }

    createFormData(form) {
        let _formData;
        _formData = new FormData();
        for (const [key, value] of Object.entries(form)) {
            if ( (typeof value) === 'object' ) {
                // @ts-ignore
                for ( let [objKey, objValue] of Object.entries(value) ) {
                    _formData.append(String(objKey), String(objValue));
                }
                continue;
            }
            _formData.append(String(key), String(value));
        }
        return _formData;
    }



    submit(formData: any, url: string, method: string, token: string):any{
        if(method == 'GET'){
            return axios.get(url, {
                withCredentials: false,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token
                }
            })
        }else if(method == 'POST'){

            return axios.post(url, formData, {
                withCredentials: false,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token
                },
            })
        }
    };


    login = (formElements) => {
        return axios.post(this.login_url, formElements, {
            withCredentials: false,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    }



}

export const connectService = new ConnectService();
