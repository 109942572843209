
















import Vue from "vue";
import {stepperService, StepperService} from "@/service/stepper";
import {step} from "@/model/model";
import {Component, Watch, Emit} from "vue-property-decorator";
import './loader.less'


@Component({
  components: {}
})
export default class loader extends Vue {

}
