














































import Vue from "vue";
import {stepperService, StepperService} from "@/service/stepper";
import {language} from "@/model/model";
import {Component, Watch, Emit} from "vue-property-decorator";
import './language-selector-mini.less'
import {translateService} from "@/service/translate";


@Component({
    components: {}
})
export default class languageSelectorMini extends Vue {
    public isSelectorOpen = false;
    public language :any[] = [];
    mounted() {
        translateService.data.subscribe(
            (ev) => {
                if (ev.event == 'update') {
                    this.language = ev.lang;
                }
            }
        )

    }
    selectLang(lang) {
        this.isSelectorOpen = !this.isSelectorOpen;
        translateService.setSelectedLanguage(lang);
    }

}
