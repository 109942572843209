import {ReplaySubject, Subject} from "rxjs";
import {transEvent} from "@/model/model";

export class TranslateService {
    private _selectedLanguage = "hu";

    private trans = {
        "home_title": {
            "hu": "Beléptetés a Calmit bányába",
            "en": "Entering to Calmit mine",
            "de": "Eintritt in die Calmit Mine",
            "sk": "Vstup do kalmitových baní"
        },
        "stepper_login_code": {
            "hu": "Belépési kód",
            "en": "Entry code",
            "de": "Eintrittcode",
            "sk": "Vstupný kód"
        },
        "stepper_freight_details": {
            "hu": "Fuvar adatok",
            "en": "Shipping data",
            "de": "Versanddaten",
            "sk": "Dodacie údaje"
        },
        "stepper_transport_data": {
            "hu": "Szállítói adatok",
            "en": "Transporter data",
            "de": "Transporterdaten",
            "sk": "Údaje transportéra"
        },
        "stepper_statements": {
            "hu": "Nyilatkozatok",
            "en": "Legal statements",
            "de": "Rechtliche Hinweise",
            "sk": "Právne vyhlásenia"
        },
        "stepper_information": {
            "hu": "Tájékoztató",
            "en": "Mine information",
            "de": "Meine Informationen",
            "sk": "Banské informácie"
        },
        "language_selector_hungarian": {
            "hu": "Magyar",
            "en": "Magyar",
            "de": "Magyar",
            "sk": "Magyar"
        },
        "language_selector_english": {
            "hu": "English",
            "en": "English",
            "de": "English",
            "sk": "English"
        },
        "language_selector_german": {
            "hu": "Deutsch",
            "en": "Deutsch",
            "de": "Deutsch",
            "sk": "Deutsch"
        },
        "language_selector_slovak": {
            "hu": "Slovenčina",
            "en": "Slovenčina",
            "de": "Slovenčina",
            "sk": "Slovenčina"
        },
        "delete": {
            "hu": "Törlés",
            "en": "Delete",
            "de": "Löschen",
            "sk": "Vymazať"
        },
        "entry_code_title": {
            "hu": "Belépési azonosító",
            "en": "Entry code",
            "de": "Eintrittcode",
            "sk": "Vstupný kód"
        },
        "entry_code_desc": {
            "hu": "A bányába történő belépéshez adja meg az értesítőben szereplő azonosítót",
            "en": "Type the code from the notification to enter the mine",
            "de": "Geben Sie den Code aus der Benachrichtigung ein, um die Mine zu betreten",
            "sk": "Zadajte kód z oznámenia a vstúpte do bane"
        },
        "ok": {
            "hu": "Rendben",
            "en": "OK",
            "de": "OK",
            "sk": "OK"
        },
        "pop_error_1_title": {
            "hu": "Inaktív azonosító!",
            "en": "Inactive entry code",
            "de": "Inaktiver Eintrittcode",
            "sk": "Neaktívny vstupný kód"
        },
        "pop_error_1_desc": {
            "hu": "A fuvar deaktiválva lett! </br> \nVegye fel a kapcsolatot a fuvarszervezővel!  </br> \nForuljon vissza!",
            "en": "Shipping is deactivated </br>Please contact your transport operator!</br>Turn back!",
            "de": "Der Versand ist deaktiviert </br>Bitte wenden Sie sich an Ihren Transportveranstalter!</br>Kehren Sie um!",
            "sk": "Doprava je deaktivovaná</br>\r\nKontaktujte svojho prepravného kontrolóra</br>\r\nOtočiť sa!"
        },
        "error_entry_code_general": {
            "hu": "Hiba történt, próbálkozzon újra, vagy kérjen segítséget!",
            "en": "An error occurred please try again!",
            "de": "Es ist ein Fehler aufgetreten. Versuchen Sie es erneut!",
            "sk": "Vyskytla sa chyba, skúste to znova!"
        },
        "error_not_valid_entry_code": {
            "hu": "A megadott azonosító nem létezik! Vegye fel a kapcsolatot a fuvarszervezőjével!",
            "en": "Entry code does not exist! Please contact your transport operator!",
            "de": "Eintrittcode existiert nicht! Bitte wenden Sie sich an Ihren Transportveranstalter!",
            "sk": "Vstupný kód neexistuje! Kontaktujte svojho prepravného kontrolóra"
        },
        "freight_title": {
            "hu": "Ezért a fuvarért jött?",
            "en": "Is it your shipping?",
            "de": "Ist es Ihr Versand?",
            "sk": "Je toto správne dodanie?"
        },
        "freight_desc": {
            "hu": "Ellenőrizze a következő fuvar adatokat",
            "en": "Please check the shipping data below",
            "de": "Bitte überprüfen Sie die Versanddaten unten",
            "sk": "Skontrolujte nižšie uvedené dodacie údaje"
        },
        "freight_code": {
            "hu": "Fuvar azonosító",
            "en": "Shipping ID",
            "de": "Versand ID",
            "sk": "Prepravné ID"
        },
        "freight_company_name": {
            "hu": "Szállítmányozó cég",
            "en": "Transporter company",
            "de": "Transportunternehmen",
            "sk": "Transportér"
        },
        "freight_customer_name": {
            "hu": "Megrendelő neve",
            "en": "Customer name",
            "de": "Kundenname",
            "sk": "Meno zákazníka"
        },
        "freight_address": {
            "hu": "Szállítási cím",
            "en": "Delivery address",
            "de": "Lieferadresse",
            "sk": "Adresa dodania"
        },
        "freight_product": {
            "hu": "Termék",
            "en": "Product",
            "de": "Produkt",
            "sk": "Výrobok"
        },
        "freight_quantity": {
            "hu": "Mennyiség",
            "en": "Quantity",
            "de": "Menge",
            "sk": "Množstvo"
        },
        "yes": {
            "hu": "Igen",
            "en": "Yes",
            "de": "Ja",
            "sk": "Áno"
        },
        "no": {
            "hu": "Nem",
            "en": "No",
            "de": "Nein",
            "sk": "Nie"
        },
        "transport_title": {
            "hu": "Szállítói adatok",
            "en": "Transporter data",
            "de": "Transporterdaten",
            "sk": "Údaje transportéra"
        },
        "transport_id": {
            "hu": "Fuvar azonosító",
            "en": "Shipping ID",
            "de": "Versand ID",
            "sk": "Prepravné ID"
        },
        "transport_truck_number_plate": {
            "hu": "Vontató rendszáma",
            "en": "Tractor plate number",
            "de": "Kennzeichen des Traktors",
            "sk": "Štítok traktora"
        },
        "transport_bill_of_lading_number": {
            "hu": "Fuvarlevél száma",
            "en": "Delivery note number",
            "de": "Lieferscheinnummer",
            "sk": "Číslo dodacieho listu"
        },
        "transport_trailer_number_plate": {
            "hu": "Vontatmány rendszáma",
            "en": "Trailer plate number",
            "de": "Kennzeichen des Anhängers",
            "sk": "Číslo tabuľky prívesu"
        },
        "transport_driver_name": {
            "hu": "Sofőr neve",
            "en": "Driver",
            "de": "Fahrer",
            "sk": "Vodič"
        },
        "transport_truck_weight_max_allowed": {
            "hu": "Szerelvény max.  össztömege",
            "en": "Maximum total weight",
            "de": "Maximales Gesamtgewicht",
            "sk": "Max. hmotnosť zhromaždenia"
        },
        "transport_valid_data": {
            "hu": "Adatok rendben",
            "en": "Data are valid",
            "de": "Daten sind gültig",
            "sk": "Údaje platné"
        },
        "transport_error_license_plaete_number": {
            "hu": "Amennyiben nem találja a megfelelő rendszámot, vegye fel a kapcsolatot a fuvarszervezővel!",
            "en": "If you cannot find your plate number contact your transport operator!",
            "de": "Wenn Sie Ihr Kennzeichen nicht finden können, wenden Sie sich an Ihr Transportveranstalter!",
            "sk": "Ak nemôžete nájsť číslo poznávacej značky, kontaktujte svojho prepravného kontrolóra!"
        },
        "transport_error_truck_number_plate": {
            "hu": "Kitöltése kötelező",
            "en": "Required",
            "de": "Erforderlich",
            "sk": "Požadovaný"
        },
        "transport_error_bill_of_lading_number": {
            "hu": "Kitöltése kötelező",
            "en": "Required",
            "de": "Erforderlich",
            "sk": "Požadovaný"
        },
        "transport_error_trailer_number_plate": {
            "hu": "Kitöltése kötelező",
            "en": "Required",
            "de": "Erforderlich",
            "sk": "Požadovaný"
        },
        "transport_error_driver_name": {
            "hu": "Kitöltése kötelező",
            "en": "Required",
            "de": "Erforderlich",
            "sk": "Požadovaný"
        },
        "transport_error_truck_weight_max_allowed": {
            "hu": "Kitöltése kötelező",
            "en": "Required",
            "de": "Erforderlich",
            "sk": "Požadovaný"
        },
        "transport_error_valid_data": {
            "hu": "Kitöltése kötelező",
            "en": "Required",
            "de": "Erforderlich",
            "sk": "Požadovaný"
        },
        "transport_error_transport_error_driver_name_id": {
            "hu": "Kitöltése kötelező",
            "en": "Required",
            "de": "Erforderlich",
            "sk": "Požadovaný"
        },
        "transport_error_truck_id": {
            "hu": "Kitöltése kötelező",
            "en": "Required",
            "de": "Erforderlich",
            "sk": "Požadovaný"
        },
        "transport_error_trailer_id": {
            "hu": "Kitöltése kötelező",
            "en": "Required",
            "de": "Erforderlich",
            "sk": "Požadovaný"
        },
        "transport_error_accept": {
            "hu": "Elfogadása kötelező",
            "en": "Required",
            "de": "Erforderlich",
            "sk": "Požadovaný"
        },
        "id": {
            "hu": "Fuvar azonosító",
            "en": "Shipping ID",
            "de": "Versand ID",
            "sk": "Prepravné ID"
        },
        "partner": {
            "hu": "Szállítmányozó cég",
            "en": "Transporter",
            "de": "Transporter",
            "sk": "Transportér"
        },
        "address": {
            "hu": "Szállítási cím",
            "en": "Delivery address",
            "de": "Lieferadresse",
            "sk": "Adresa dodania"
        },
        "product": {
            "hu": "Termék",
            "en": "Product",
            "de": "Produkt",
            "sk": "Výrobok"
        },
        "quantity": {
            "hu": "Mennyiség",
            "en": "Quantity",
            "de": "Menge",
            "sk": "Množstvo"
        },
        "next_button": {
            "hu": "Küldés",
            "en": "Send",
            "de": "Senden",
            "sk": "Odoslať"
        },
        "transport_error_no_driver_name": {
            "hu": "Amennyiben nem találja a megfelelő rendszámot, vegye fel a kapcsolatot a fuvarszervezővel!",
            "en": "If you cannot find your plate number contact your transport controller!",
            "de": "Wenn Sie Ihr Kennzeichen nicht finden können, wenden Sie sich an Ihr Transportveranstalter!",
            "sk": "Ak nemôžete nájsť číslo poznávacej značky, kontaktujte svojho prepravného kontrolóra!"
        },
        "transport_error_no_truck_number_plate": {
            "hu": "Amennyiben nem találja a megfelelő rendszámot, vegye fel a kapcsolatot a fuvarszervezővel!",
            "en": "If you cannot find your plate number contact your transport controller!",
            "de": "Wenn Sie Ihr Kennzeichen nicht finden können, wenden Sie sich an Ihr Transportveranstalter!",
            "sk": "If you cannot find your plate number contact your transport controller!"
        },
        "transport_error_no_trailer_number_plate": {
            "hu": "Amennyiben nem találja a megfelelő rendszámot, vegye fel a kapcsolatot a fuvarszervezővel!",
            "en": "If you cannot find your plate number contact your transport controller!",
            "de": "Wenn Sie Ihr Kennzeichen nicht finden können, wenden Sie sich an Ihr Transportveranstalter!",
            "sk": "If you cannot find your plate number contact your transport controller!"
        },
        "statement_title": {
            "hu": "Nyilatkozatok áttekintése",
            "en": "Legal statements",
            "de": "Rechtliche Hinweise",
            "sk": "Prehľad právneho vyhlásenia"
        },
        "statement_accept": {
            "hu": "A nyilatkozatot elfogadom",
            "en": "Agree",
            "de": "Zustimmen",
            "sk": "Súhlasím"
        },
        "statement_accept_error": {
            "hu": "A nyilatkozatot el kel fogadni",
            "en": "Acceptance is required",
            "de": "Eine Abnahme ist erforderlich",
            "sk": "Právne predpisy musia byť prijaté"
        },
        "next": {
            "hu": "Tovább",
            "en": "Next",
            "de": "Nächste",
            "sk": "Ďalšie"
        },
        "actual_message_title": {
            "hu": "Aktuális üzeneteink",
            "en": "Our current messages",
            "de": "Unsere aktuellen Meldungen",
            "sk": "Aktuálne správy"
        },
        "i_read": {
            "hu": "Elolvastam",
            "en": "Understood",
            "de": "Verstanden",
            "sk": "Rozumel"
        },
        "reload": {
            "hu": "Újrakezdés",
            "en": "Restart",
            "de": "Wieder starten",
            "sk": "Znova načítať"
        },
        "succes_login": {
            "hu": "Sikeres Belépés",
            "en": "Success: you can enter",
            "de": "Erfolg: Sie können eintreten",
            "sk": "Teraz môžete vstúpiť"
        },
        "go_to_balance": {
            "hu": "Kérem hajtson fel a mérlegre! <br>\nA sikeres mérlegelést a forgalmi lámpa zöldre váltása fogja jelezni Önnek.",
            "en": "Please drive onto the scale!</br>Successful measurement will be indicated by turning the traffic light green.",
            "de": "Bitte fahren Sie zur Waage!</br>Eine erfolgreiche Messung wird durch das Aufleuchten der Ampel auf Grün angezeigt",
            "sk": "Prejdite na stupnicu</br>\r\nÚspešné rokovanie vám bude indikované otočením semaforu na zeleno."
        },
        "after_go_to_depo": {
            "hu": "Ezután kérjük, hajtson a(z) depo_number MÉSZKŐ depóhoz!",
            "en": "Please drive to the [] depot",
            "de": "Bitte fahren Sie zum Betriebshof [].",
            "sk": "Choďte do depa []"
        },
        "error_accept_statement": {
            "hu": "Elfogadása kötelező",
            "en": "Acceptance is required",
            "de": "Eine Abnahme ist erforderlich",
            "sk": "Vyžaduje sa prijatie"
        },
        "accept_statement": {
            "hu": "A nyilatkozatot elfogadom",
            "en": "Agree",
            "de": "Zustimmen",
            "sk": "Súhlasím"
        },
        "pop_error_2_title": {
            "hu": "Felhasznált azonosító!",
            "en": "Used entry code",
            "de": "Eintrittcode verwendet",
            "sk": "Použitý vstupný kód"
        },
        "pop_error_2_desc": {
            "hu": "Ezt az azonosítót egy korábbi fuvarban már felhasználta!",
            "en": "You have already used this entry code in a previous shipping!",
            "de": "Sie haben diesen Eintrittcode bereits bei einem früheren Versand verwendet!",
            "sk": "Tento vstupný kód ste už použili pri predchádzajúcej zásielke!"
        },
        "error_login": {
            "hu": "A bányába való beléptetés nem sikerült. Kérjük, kérjen segítséget a személyzettől!",
            "en": "Entry failed! Please ask the staff for help.",
            "de": "Eintritt fehlgeschlagen! Bitte fragen Sie das Personal um Hilfe.",
            "sk": "Vstup zlyhal! Požiadajte o pomoc personál."
        },
        "system_info": {
            "hu": "A rendszer által adott hibaüzenet:",
            "en": "System error message",
            "de": "Systemfehlermeldung",
            "sk": "Systémová chybová správa"
        },
        "token_not_found": {
            "hu": "A belépési token nem található. Ellenőrizze, hogy helyesen gépelte-e be, szükség esetén kérje a személyzet segítségét.",
            "en": "Entry code not found. Check that you have typed it correctly, ask the staff for help if necessary.",
            "de": "Eintrittcode nicht gefunden. Überprüfen Sie, ob Sie es richtig getippt haben, und bitten Sie gegebenenfalls das Personal um Hilfe.",
            "sk": "Vstupný kód sa nenašiel. Skontrolujte, či ste ho napísali správne, v prípade potreby požiadajte o pomoc personál."
        },
        "t1entry_error": {
            "hu": "Sikertelen beléptetés: technikai hiba. Kérje a személyzet segítségét!",
            "en": "Entry failed: technical error. Ask the staff for help!",
            "de": "Eintritt fehlgeschlage: technischer Fehler. Bitten Sie das Personal um Hilfe!",
            "sk": "Zadanie zlyhalo: technická chyba. Požiadajte personál o pomoc!"
        },
        "t1entry_delivery_address_error": {
            "hu": "Sikertelen beléptetés: a szállítási cím még nem került visszaigazolásra a rendszerünkben. Kérje a személyzet segítségét!",
            "en": "Entry failed: the delivery address has not yet been confirmed in our system. Ask the staff for help!",
            "de": "Eintritt fehlgeschlage: die Lieferadresse wurde noch nicht in unserem System bestätigt. Bitten Sie das Personal um Hilfe!",
            "sk": "Zadanie zlyhalo: doručovacia adresa ešte nebola potvrdená v našom systéme. Požiadajte personál o pomoc!"
        },
        "undefined_error": {
            "hu": "Váratlan hiba",
            "en": "Unexpected error",
            "de": "Unerwarteter Fehler",
            "sk": "Neočakávaná chyba"
        }
    };

    private languages = [
        {
            id: 0,
            name: this.getTranslation('language_selector_hungarian'),
            shortName: 'hu',
            countryIcon: '<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<rect x="0.25" y="0.25" width="23.5" height="16.5" rx="1.75" fill="white" stroke="#F5F5F5" stroke-width="0.5"/>\n' +
                '<mask id="ll1" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="17">\n' +
                '<rect x="0.25" y="0.25" width="23.5" height="16.5" rx="1.75" fill="white" stroke="white" stroke-width="0.5"/>\n' +
                '</mask>\n' +
                '<g mask="url(#ll1)">\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.66667H24V0H0V5.66667Z" fill="#E03D52"/>\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.9999H24V11.3333H0V16.9999Z" fill="#5A9165"/>\n' +
                '</g>\n' +
                '</svg>\n',
            isActive: true
        },
        {
            id: 1,
            name: this.getTranslation('language_selector_english'),
            shortName: 'en',
            countryIcon: '<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<rect width="24" height="17" rx="2" fill="white"/>\n' +
                '<mask id="ll2" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="17">\n' +
                '<rect width="24" height="17" rx="2" fill="white"/>\n' +
                '</mask>\n' +
                '<g mask="url(#ll2)">\n' +
                '<rect width="24" height="17" fill="#0A17A7"/>\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M-1.09929 -1.62891L9.14283 5.22192V-1.13326H14.8571V5.22192L25.0992 -1.62891L26.3774 0.250244L18.2796 5.66674H24V11.3334H18.2796L26.3774 16.7499L25.0992 18.6291L14.8571 11.7782V18.1334H9.14283V11.7782L-1.09928 18.6291L-2.37744 16.7499L5.72033 11.3334H-2.71797e-05V5.66674H5.72032L-2.37744 0.250246L-1.09929 -1.62891Z" fill="white"/>\n' +
                '<path d="M16.0011 5.38241L26.8571 -1.69995" stroke="#DB1F35" stroke-width="0.666667" stroke-linecap="round"/>\n' +
                '<path d="M17.1538 11.6428L26.8857 18.1477" stroke="#DB1F35" stroke-width="0.666667" stroke-linecap="round"/>\n' +
                '<path d="M6.86185 5.36382L-3.28931 -1.42041" stroke="#DB1F35" stroke-width="0.666667" stroke-linecap="round"/>\n' +
                '<path d="M7.96286 11.5641L-3.28931 18.9639" stroke="#DB1F35" stroke-width="0.666667" stroke-linecap="round"/>\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.2H10.2857V17H13.7143V10.2H24V6.8H13.7143V0H10.2857V6.8H0V10.2Z" fill="#E6273E"/>\n' +
                '</g>\n' +
                '</svg>\n',
            isActive: false
        },
        {
            id: 2,
            name: this.getTranslation('language_selector_slovak'),
            shortName: 'sk',
            countryIcon: '<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<rect x="0.25" y="0.25" width="23.5" height="16.5" rx="1.75" fill="white" stroke="#F5F5F5" stroke-width="0.5"/>\n' +
                '<mask id="ll3" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="17">\n' +
                '<rect x="0.25" y="0.25" width="23.5" height="16.5" rx="1.75" fill="white" stroke="white" stroke-width="0.5"/>\n' +
                '</mask>\n' +
                '<g mask="url(#ll3)">\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M0 11.3334H24V5.66675H0V11.3334Z" fill="#0C47B7"/>\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.9999H24V11.3333H0V16.9999Z" fill="#E53B35"/>\n' +
                '<path d="M6.13972 2.73324C5.06531 2.73324 4.18286 3.58212 4.14122 4.65572L3.93034 10.093C3.90655 10.7064 4.16579 11.2968 4.63354 11.6944L6.84432 13.5735C7.84013 14.42 9.30264 14.42 10.2984 13.5735L12.5092 11.6944C12.977 11.2968 13.2362 10.7064 13.2124 10.093L13.0015 4.65572C12.9599 3.58212 12.0775 2.73324 11.003 2.73324H6.13972Z" fill="#F73744" stroke="white" stroke-width="1.33333"/>\n' +
                '<mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="2" width="11" height="13">\n' +
                '<path d="M6.13972 2.73324C5.06531 2.73324 4.18286 3.58212 4.14122 4.65572L3.93034 10.093C3.90655 10.7064 4.16579 11.2968 4.63354 11.6944L6.84432 13.5735C7.84013 14.42 9.30264 14.42 10.2984 13.5735L12.5092 11.6944C12.977 11.2968 13.2362 10.7064 13.2124 10.093L13.0015 4.65572C12.9599 3.58212 12.0775 2.73324 11.003 2.73324H6.13972Z" fill="white" stroke="white" stroke-width="1.33333"/>\n' +
                '</mask>\n' +
                '<g mask="url(#ll3)">\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.99993 9.39987C7.99993 9.21577 7.85069 9.06654 7.6666 9.06654H6.61898C6.43488 9.06654 6.28564 8.9173 6.28564 8.7332V8.26654C6.28564 8.08244 6.43488 7.9332 6.61898 7.9332H7.6666C7.85069 7.9332 7.99993 7.78396 7.99993 7.59987V7.1332C7.99993 6.94911 7.85069 6.79987 7.6666 6.79987H7.19041C7.00631 6.79987 6.85707 6.65063 6.85707 6.46654V5.99987C6.85707 5.81577 7.00631 5.66654 7.19041 5.66654H7.6666C7.85069 5.66654 7.99993 5.5173 7.99993 5.3332V4.86654C7.99993 4.68244 8.14917 4.5332 8.33326 4.5332H8.80945C8.99355 4.5332 9.14279 4.68244 9.14279 4.86654V5.3332C9.14279 5.5173 9.29203 5.66654 9.47612 5.66654H9.95231C10.1364 5.66654 10.2856 5.81577 10.2856 5.99987V6.46654C10.2856 6.65063 10.1364 6.79987 9.95231 6.79987H9.47612C9.29203 6.79987 9.14279 6.94911 9.14279 7.1332V7.59987C9.14279 7.78396 9.29203 7.9332 9.47612 7.9332H10.5237C10.7078 7.9332 10.8571 8.08244 10.8571 8.26654V8.7332C10.8571 8.9173 10.7078 9.06654 10.5237 9.06654H9.47612C9.29203 9.06654 9.14279 9.21577 9.14279 9.39987V10.9999C9.14279 11.184 8.99355 11.3332 8.80945 11.3332H8.33326C8.14917 11.3332 7.99993 11.184 7.99993 10.9999V9.39987Z" fill="white"/>\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M9.60298 11.4116C9.41902 11.0301 9.02623 10.7666 8.57138 10.7666C8.11653 10.7666 7.72375 11.0301 7.53978 11.4116C6.15034 11.6287 5.14282 12.2726 5.14282 13.0333C5.14282 13.9722 6.67785 14.7333 8.57139 14.7333C10.4649 14.7333 12 13.9722 12 13.0333C12 12.2726 10.9924 11.6287 9.60298 11.4116Z" fill="#1251A1"/>\n' +
                '</g>\n' +
                '</g>\n' +
                '</svg>\n',
            isActive: false
        },
        {
            id: 3,
            name: this.getTranslation('language_selector_german'),
            shortName: 'de',
            countryIcon: '<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<rect width="24" height="17" rx="2" fill="white"/>\n' +
                '<mask id="ll5" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="17">\n' +
                '<rect width="24" height="17" rx="2" fill="white"/>\n' +
                '</mask>\n' +
                '<g mask="url(#ll5)">\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.66667H24V0H0V5.66667Z" fill="#262626"/>\n' +
                '<g filter="url(#filter0_d)">\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M0 11.3334H24V5.66675H0V11.3334Z" fill="#F01515"/>\n' +
                '</g>\n' +
                '<g filter="url(#filter1_d)">\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.9999H24V11.3333H0V16.9999Z" fill="#FFD521"/>\n' +
                '</g>\n' +
                '</g>\n' +
                '<defs>\n' +
                '<filter id="filter0_d" x="0" y="5.66675" width="24" height="5.66667" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
                '<feFlood flood-opacity="0" result="BackgroundImageFix"/>\n' +
                '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>\n' +
                '<feOffset/>\n' +
                '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>\n' +
                '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>\n' +
                '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>\n' +
                '</filter>\n' +
                '<filter id="filter1_d" x="0" y="11.3333" width="24" height="5.66667" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
                '<feFlood flood-opacity="0" result="BackgroundImageFix"/>\n' +
                '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>\n' +
                '<feOffset/>\n' +
                '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>\n' +
                '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>\n' +
                '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>\n' +
                '</filter>\n' +
                '</defs>\n' +
                '</svg>\n',
            isActive: false
        }
    ]

    constructor() {
        this._data = new ReplaySubject<transEvent>();
        this.data.next({
            event: 'update',
            lang: this.getAlllanguages(),
            selectedLang: this.getSelectedLanguage()
        });
        (this.data as Subject<transEvent>).next({
            event: 'update',
            lang: this.getAlllanguages(),
            selectedLang: this.getSelectedLanguage()
        });

    }

    _data: ReplaySubject<transEvent> = new ReplaySubject<transEvent>();


    public get data(): ReplaySubject<transEvent> {
        return this._data;
    }

    public set data(c: ReplaySubject<transEvent>) {
        this._data = c;
    }

    get selectedLanguage(): string {
        return this._selectedLanguage;
    }

    set selectedLanguage(value: string) {
        this._selectedLanguage = value;
        this.languages.map((l) => {
            if (l.shortName == value) {
                l.isActive = true;
            } else {
                l.isActive = false;
            }
        });

        (this.data as Subject<transEvent>).next({
            event: 'update',
            lang: this.getAlllanguages(),
            selectedLang: this.getSelectedLanguage()
        });

    }

    setSelectedLanguage(lang) {
        this.selectedLanguage = lang;
    }

    getSelectedLanguage() {
        return this.selectedLanguage;
    }

    getAlllanguages() {
        return this.languages;
    }

    getTranslation(key, index = 0) {
        if (key == "" && typeof this.trans[key] != "undefined") {
            return "";
        }
        if (typeof this.trans[key] == "object" && typeof this.trans[key][this.selectedLanguage] == "string") {
            return this.trans[key][this.selectedLanguage];
        } else {
            if (typeof this.trans[key] != "undefined" && typeof this.trans[key]['hu'] == "string") {
                return this.trans[key]['hu'];
            } else {
                return key;
            }
        }
    }


}

export const translateService = new TranslateService();