import {ReplaySubject, Subject} from "rxjs";
import {
    language,
    stepperEvent,
    transEvent,
    carriage,
    transporter_data,
    carriageEvent,
    statmentEvent
} from "@/model/model";

export class DataService {
    private _loginToken: string =''

    private carriage: carriage = {
        id: '',
        partner: '',
        address: '',
        transporter: '',
        product: '',
        quantity: '',
        type: 1,
        shipping_type: ''
    };

    private _transporter_data: transporter_data = {
        transport_id: '',
        shipping_note_number: '',
        driver_name: '',
        truck_number_plate: '',
        trailer_number_plate: '',
        driver_id: 0,
        truck_id: 0,
        trailer_id: 0,
        maximum_weight: '',
        transportIsCustomer: false,
        transportAccept: false,
        driver_data: [],
        truck_data: [],
        trailer_data: [],
        trailer_filtered_data: [],
        organizationable_type: '',
        organizationable_id: '',
        contruck_fulfillment: 0,
        truck_type: -1,
        trailer_type: -1,
    };

    private _token: number = 0;

    private statement_data: [] = [];
    private entry_error_message ='';

    constructor() {
        this._data = new ReplaySubject<carriageEvent>();
        this.data.next({
            event: 'updateCarriage',
            data: this.getCarriage(),
            transporter_data: this.getTransporterData()
        })
        this._statment_data.next({
            event: 'update',
            data: this.getStatementsData()
        })
    }

    private _data: ReplaySubject<carriageEvent> = new ReplaySubject<carriageEvent>();
    private _statment_data: ReplaySubject<statmentEvent> = new ReplaySubject<statmentEvent>();


    public get data(): ReplaySubject<carriageEvent> {
        return this._data;
    }

    public set data(c: ReplaySubject<carriageEvent>) {
        this._data = c;
    }
    public get statementData(): ReplaySubject<statmentEvent> {
        return this._statment_data;
    }

    public set statementData(c: ReplaySubject<statmentEvent>) {
        this._statment_data = c;
    }


    setCarriage(shippingData, shipping_type) {
        let trailer_type_id = -1
        if(shippingData && shippingData.trailer && shippingData.trailer.type_id){
            trailer_type_id = shippingData.trailer.type_id
        }
        if(shipping_type.indexOf('FuelShipping') > -1){
            this.carriage = {
                id: shippingData.id,
                partner: 'Calmit üzemanyag szállító',
                address: '',
                transporter: '',
                product: '',
                quantity: '',
                formated_quantity: '',
                type: 1,
                shipping_type: shipping_type,
            }
            this.setTransportData(shippingData.id,
                shippingData.shipping_note_number,
                shippingData.driver.first_name +' '+ shippingData.driver.last_name,
                shippingData.truck.number_plate,
                shippingData.trailer != null ? shippingData.trailer.number_plate : '',
                shippingData.driver.id,
                shippingData.truck.id,
                shippingData.trailer != null ? shippingData.trailer.id : '',
                shippingData.maximum_weight,
                1,
                false,
                [],
                [],
                [],
                shippingData.driver.organizationable_type,
                shippingData.driver.organizationable_id,
                '',
                shippingData.truck.type_id,
                trailer_type_id,
            )
        }else{
            this.carriage = {
                id: shippingData.id,
                partner: shippingData.organizationable.name,
                address: this.getAddress(shippingData.order_item.order),
                transporter: shippingData.order_item.order.partner_name,
                product: shippingData.order_item.product_name,
                quantity: shippingData.shipment_quantity,
                formated_quantity: shippingData.shipment_quantity.toString().replace(/\D/g, '').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1 ") + ' kg',
                type: 1,
                shipping_type: shipping_type,
            }
            this.setTransportData(shippingData.id,
                shippingData.shipping_note_number,
                shippingData.driver.first_name +' '+ shippingData.driver.last_name,
                shippingData.truck.number_plate,
                shippingData.trailer != null ? shippingData.trailer.number_plate : '',
                shippingData.driver.id,
                shippingData.truck.id,
                shippingData.trailer != null ? shippingData.trailer.id : '',
                shippingData.maximum_weight,
                1,
                false,
                [],
                [],
                [],
                shippingData.organizationable_type,
                shippingData.organizationable_id,
                shippingData.contruck_fulfillment,
                shippingData.truck.type_id,
                trailer_type_id,
            )
        }



        this.data.next({
            event: 'updateTransport',
            data: this.getCarriage(),
            transporter_data: this.getTransporterData()
        })
    }


    setTransportData(transport_id,
                     shipping_note_number,
                     driver_name,
                     truck_number_plate,
                     trailer_number_plate,
                     driver_id,
                     truck_id,
                     trailer_id,
                     maximum_weight,
                     transportIsCustomer,
                     transportAccept,
                     driver_data,
                     truck_data,
                     trailer_data,
                     organizationable_type,
                     organizationable_id,
                     contruck_fulfillment,
                     truck_type,
                     trailer_type,
    ) {
        this._transporter_data = {
            transport_id: transport_id,
            shipping_note_number: shipping_note_number,
            driver_name: driver_name,
            truck_number_plate: truck_number_plate,
            trailer_number_plate: trailer_number_plate,
            driver_id: driver_id,
            truck_id: truck_id,
            trailer_id: trailer_id,
            maximum_weight: maximum_weight,
            transportIsCustomer: transportIsCustomer,
            transportAccept: transportAccept,
            driver_data: driver_data,
            truck_data: truck_data,
            trailer_data: trailer_data,
            trailer_filtered_data: trailer_data,
            organizationable_type: organizationable_type,
            organizationable_id: organizationable_id,
            contruck_fulfillment: contruck_fulfillment,
            truck_type: truck_type,
            trailer_type: trailer_type,
        }
        this.data.next({
            event: 'updateTransport',
            data: this.getCarriage(),
            transporter_data: this.getTransporterData()
        })

    }


    resetCarriage() {
        this.carriage = {
            id: '',
            partner: '',
            address: '',
            transporter: '',
            product: '',
            quantity: '',
            type: 1,
            shipping_type: ''
        }
        this.resetTransportData()


        this.data.next({
            event: 'resetCarriage',
            data: this.getCarriage(),
            transporter_data: this.getTransporterData()
        })
    }


    resetTransportData() {
        this.setTransportData('',
            '',
            '',
            '',
            '',
            0,
            0,
            0,
            '',
            false,
            false,
            [],
            [],
            [],
            '',
            '',
            0,
            '',
            ''
        )

    }

    setTransportDataDriver(drivers){
        this._transporter_data.driver_data = drivers;
        this.data.next({
            event: 'updateTransport',
            data: this.getCarriage(),
            transporter_data: this.getTransporterData()
        })
    }
    setTransportDataTruck(truck){
        this._transporter_data.truck_data = truck;
        this.data.next({
            event: 'updateTransport',
            data: this.getCarriage(),
            transporter_data: this.getTransporterData()
        })
    }
    setTransportDataTrailer(trailer){
        this._transporter_data.trailer_data = trailer;
        this.data.next({
            event: 'updateTransport',
            data: this.getCarriage(),
            transporter_data: this.getTransporterData()
        })
    }

    setTransportDataModel(transData) {
        this._transporter_data = transData;

    }

    getCarriage() {
        return this.carriage;
    }

    getTransporterData() {
        return this._transporter_data;
    }

    getToken() {
        return this._token;
    }

    setToken(token) {
        this._token = token;
    }

    getLoginToken() {
        return this._loginToken;
    }

    setLoginToken(loginToken) {
        this._loginToken = loginToken;
    }

    getTransDataTypeCustomer() {
        return {
            // '_method': 'PUT',
            'shipping_id': this._transporter_data.transport_id,
            'country_code_id': 76,
            'truck_vehicle_id': this._transporter_data.truck_id,
            'trailer_vehicle_id': this._transporter_data.trailer_id,
            'driver_id': this._transporter_data.driver_id,
            'shipping_note_number': this._transporter_data.shipping_note_number,
            'maximum_weight': this._transporter_data.maximum_weight.split(' ').join(''),
            'token': this._token,
            'message': '',
            'shipping_type': this.carriage.shipping_type,
        }
    }

    getTransDataTypeCalmit() {
        return {
            // '_method': 'PUT',
            'shipping_id': this._transporter_data.transport_id,
            'country_code_id': 76,
            'truck_vehicle_id': this._transporter_data.truck_id,
            'trailer_vehicle_id': this._transporter_data.trailer_id,
            'driver_id': this._transporter_data.driver_id,
            'shipping_note_number': this._transporter_data.shipping_note_number,
            'maximum_weight': this._transporter_data.maximum_weight.split(' ').join(''),
            'token': this._token,
            'message': '',
            'shipping_type': this.carriage.shipping_type,
        }
    }
    getAddress(data){
        return data.delivery_address_zip_code+ " "
            +data.delivery_address_city+ ", "
            +data.delivery_address_street+ " "
            +data.delivery_address_street_type+" "
            +(data.delivery_address_street_number !='' ? data.delivery_address_street_number+"." : data.delivery_address_parcel_number)
    }


    getStatements(statement_data) {
        let sendData: any = [];
        statement_data.map((statement, index) => {
            sendData.push({
                'legal_statement_ids[]' : statement.id
            });
        })
        return sendData;
    }


    getStatementsData(){
        return this.statement_data
    }
    setStatementsData(statments :any){
        this.statement_data = statments
    }
    getEntryErrorMessage(){
        return this.entry_error_message
    }
    setEntryErrorMessage(error :string){
        this.entry_error_message = error
    }


}

export const dataService = new DataService();